import { BaseModal } from './BaseModal'
import { SettingsToggle } from './SettingsToggle'
import packageInfo from '../../../package.json'
import {
  HARD_MODE_DESCRIPTION,
  HIGH_CONTRAST_MODE_DESCRIPTION,
} from '../../constants/strings'

type Props = {
  isOpen: boolean
  handleClose: () => void
  isHardMode: boolean
  handleHardMode: Function
  isDarkMode: boolean
  handleDarkMode: Function
  isHighContrastMode: boolean
  handleHighContrastMode: Function
}

export const SettingsModal = ({
  isOpen,
  handleClose,
  isHardMode,
  handleHardMode,
  isDarkMode,
  handleDarkMode,
  isHighContrastMode,
  handleHighContrastMode,
}: Props) => {
  return (
    <BaseModal title="Նախընտրանքներ" isOpen={isOpen} handleClose={handleClose}>
      <div className="flex flex-col mt-2">
        <SettingsToggle
          settingName="Դժուարացնել"
          flag={isHardMode}
          handleFlag={handleHardMode}
          description={HARD_MODE_DESCRIPTION}
        />
        <SettingsToggle
          settingName="Մթնցնել"
          flag={isDarkMode}
          handleFlag={handleDarkMode}
        />
        <SettingsToggle
          settingName="Գունակոյր"
          flag={isHighContrastMode}
          handleFlag={handleHighContrastMode}
          description={HIGH_CONTRAST_MODE_DESCRIPTION}
        />
        <p className="mt-6 text-sm text-gray-500 dark:text-gray-300">
          Բառիկ is a Western Armenian{' '}
          <a
            href="https://www.nytimes.com/games/wordle/index.html"
            className="underline font-bold"
          >
            Wordle
          </a>
        </p>
        <p className="mt-1 text-sm text-gray-500 dark:text-gray-300">
          Adapted by{' '}
          <a
            href="https://twitter.com/sourenig"
            className="underline font-bold"
          >
            @sourenig
          </a>{' '}
          and{' '}
          <a
            href="https://twitter.com/ThePoliticsWand"
            className="underline font-bold"
          >
            @ThePoliticsWand
          </a>
        </p>
        <p className="mt-1 text-sm text-gray-500 dark:text-gray-300">
          Using open source{' '}
          <a
            href="https://github.com/cwackerfuss/react-wordle"
            className="underline font-bold"
          >
            code
          </a>{' '}
        </p>
        <p className="mt-2 text-sm text-gray-500 dark:text-gray-300">
          Reformed Armenian version:{' '}
          <a
            href="https://gagikm.github.io/bardl/"
            className="underline font-bold"
          >
            bardle
          </a>
        </p>
        <p className="mt-2 text-xs text-gray-500 dark:text-gray-300">
          Version: {packageInfo.version}
        </p>
      </div>
    </BaseModal>
  )
}
