export const GAME_TITLE = process.env.REACT_APP_GAME_NAME!

export const WIN_MESSAGES = ['Հանճար', 'Հոյակապ', 'Տպաւորիչ']
export const GAME_COPIED_MESSAGE = 'Ընդօրինակեց'
export const NOT_ENOUGH_LETTERS_MESSAGE = 'Տառերը քիչ են'
export const WORD_NOT_FOUND_MESSAGE = 'Բառերու ցանկին մէջ չկայ'
export const HARD_MODE_ALERT_MESSAGE = 'Միայն խաղին սկիզբը կրնաս դժուար կերպը միացնել'
export const HARD_MODE_DESCRIPTION = 'Ճիշդ գուշակուած տառերը պէտք է յաջորդ փորձին գործածուին:'
export const HIGH_CONTRAST_MODE_DESCRIPTION = 'Բարձր հակադրութեամբ գոյներ:'
export const CORRECT_WORD_MESSAGE = (solution: string) =>
  `Բառը «${solution}» էր`
export const WRONG_SPOT_MESSAGE = (guess: string, position: number) =>
  `Պէտք է «${guess}» գործածել ${position} տուփիկին մէջ`
export const NOT_CONTAINED_MESSAGE = (letter: string) =>
  `${letter} տառը պէտք է ըլլայ փորձիդ մէջ`
export const ENTER_TEXT = 'Enter'
export const DELETE_TEXT = 'Del'
export const STATISTICS_TITLE = 'ՎԻՃԱԿԱԳՐՈՒԹԻՒՆ'
export const GUESS_DISTRIBUTION_TEXT = 'ՓՈՐՁԵՐՈՒ ՀԱՇԻՒԸ'
export const NEW_WORD_TEXT = 'ՅԱՋՈՐԴ ԲԱՌԸ'
export const SHARE_TEXT = 'ԲԱԺՆԵԼ'
export const TOTAL_TRIES_TEXT = 'Խաղցած'
export const SUCCESS_RATE_TEXT = 'Լուծուած %'
export const CURRENT_STREAK_TEXT = 'Ընթացիկ յաղթական շարքը'
export const BEST_STREAK_TEXT = 'Ամէնէն երկար յաղթական շարքը'
