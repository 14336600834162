export const WORDS = [
    'կէսօր',
    'դաժան',
    'շշուկ',
    'ձկնիթ',
    'երբեք',
    'հտպիտ',
    'արձակ',
    'խունկ',
    'իյնալ',
    'շորիկ',
    'գրեթէ',
    'խնձոր',
    'պառաւ',
    'զուսպ',
    'ձօնել',
    'գիշեր',
    'վիրապ',
    'ոլորտ',
    'նատիր',
    'տաւար',
    'փրկել',
    'զրոյց',
    'թեթեւ',
    'ոստան',
    'ճաթիլ',
    'բաժին',
    'շեղել',
    'պահել',
    'ճռինչ',
    'ամրոց',
    'քռթիկ',
    'կարող',
    'հեծել',
    'ապրիլ',
    'քուրձ',
    'մոխիր',
    'նիհար',
    'տիկին',
    'գովել',
    'սոթել',
    'գոցել',
    'մարել',
    'հպարտ',
    'գաւաթ',
    'փիլաւ',
    'արժէք',
    'կշիռք',
    'երգիչ',
    'արճիճ',
    'նժոյգ',
    'մուրճ',
    'ինչու',
    'կապիճ',
    'ծամոն',
    'գունդ',
    'ճկուն',
    'սրտիկ',
    'ապակի',
    'սուրբ',
    'բռնիչ',
    'մտացի',
    'սմբակ',
    'պարտք',
    'չափել',
    'կայան',
    'միայն',
    'հատոր',
    'երկար',
    'սակաւ',
    'անտառ',
    'պատիր',
    'ճզմել',
    'թիթեռ',
    'ցոլալ',
    'կածան',
    'անմահ',
    'շաղափ',
    'խումբ',
    'շալակ',
    'մարախ',
    'լեզու',
    'քալել',
    'շոմին',
    'պակաս',
    'երդիք',
    'հաջող',
    'մոլոր',
    'կոչել',
    'դրախտ',
    'խաղալ',
    'ձախող',
    'խաղաղ',
    'խելօք',
    'շաբաթ',
    'յոպոպ',
    'ստոյգ',
    'օգուտ',
    'ոսկոր',
    'մոլոշ',
    'փթթիլ',
    'ուշիմ',
    'հեղեղ',
    'խաղող',
    'սաւան',
    'խնդալ',
    'կատար',
    'քննել',
    'էական',
    'որդան',
    'յօժար',
    'գետափ',
    'չղջիկ',
    'շղթայ',
    'որոնք',
    'բուրդ',
    'գլուխ',
    'հեռու',
    'անուն',
    'մթերք',
    'օրհաս',
    'շեփոր',
    'վարար',
    'մահակ',
    'արդար',
    'դիտել',
    'ամայի',
    'իրենք',
    'մրուր',
    'յառաջ',
    'լուրջ',
    'քաւոր',
    'ծաղիկ',
    'ցօղիկ',
    'տառեխ',
    'միջատ',
    'քարոտ',
    'ճնշել',
    'ճահիճ',
    'աղտոտ',
    'հազար',
    'ատեան',
    'սնդիկ',
    'մկրատ',
    'գտնել',
    'հօտաղ',
    'մսխել',
    'ուրախ',
    'շիջիլ',
    'ապուր',
    'տաւիղ',
    'նաւազ',
    'միջոց',
    'աքսոր',
    'թաղել',
    'արքայ',
    'կեղեւ',
    'սրճեփ',
    'տանիք',
    'տերեւ',
    'կամար',
    'նպաստ',
    'քերիչ',
    'խոպան',
    'լողալ',
    'զսպել',
    'վազել',
    'միջին',
    'կուշտ',
    'տոմար',
    'բորոտ',
    'բերել',
    'բանակ',
    'քորել',
    'ջրցան',
    'շաքար',
    'շոյել',
    'բուրգ',
    'փայլք',
    'թոնիր',
    'ջրվէժ',
    'գամել',
    'մռութ',
    'սրբել',
    'ծիրան',
    'աւարտ',
    'դժխեմ',
    'յօտել',
    'տապար',
    'բանալ',
    'կատակ',
    'տարափ',
    'պարէն',
    'աղօթք',
    'մեխակ',
    'օրրան',
    'հազիւ',
    'կրկին',
    'խոշոր',
    'կախիչ',
    'ռումբ',
    'յարգի',
    'տնտես',
    'զաւակ',
    'խաբել',
    'լուալ',
    'անօթի',
    'թարախ',
    'տարեց',
    'ցուրտ',
    'աւելի',
    'ննջել',
    'դիմաց',
    'կախել',
    'հսկել',
    'թունդ',
    'կծկել',
    'հուրի',
    'յաջող',
    'առտու',
    'նանիր',
    'սնանկ',
    'նամակ',
    'գրկել',
    'ապուշ',
    'իջնել',
    'իմաստ',
    'չարիք',
    'բոպիկ',
    'ջրփող',
    'ուստր',
    'դողալ',
    'ձունձ',
    'ժողով',
    'խնդիր',
    'հռչակ',
    'հեւալ',
    'նորեկ',
    'հասակ',
    'հանել',
    'գեղօր',
    'երկու',
    'գեղօն',
    'շառաչ',
    'պոռոտ',
    'աշուն',
    'պոկել',
    'քննիչ',
    'պղպեղ',
    'թիրախ',
    'կարօտ',
    'շերամ',
    'սանտր',
    'երթալ',
    'ցաւիլ',
    'գետին',
    'բարակ',
    'ցանել',
    'ճշդել',
    'ջաղաց',
    'յիշել',
    'սողալ',
    'սրինգ',
    'արդէն',
    'գնացք',
    'շուրթ',
    'ածուխ',
    'շնորհ',
    'խօսիլ',
    'քանոն',
    'հնդիկ',
    'մողէզ',
    'մորեխ',
    'ռեհան',
    'կրօնք',
    'վիպակ',
    'հոգալ',
    'լաւաշ',
    'զոհել',
    'ապուռ',
    'ջրհեղ',
    'ծեծել',
    'նիզակ',
    'շնչել',
    'անուշ',
    'աւրել',
    'չամիչ',
    'մեղու',
    'հոսիլ',
    'ոչխար',
    'կղպել',
    'ելոյթ',
    'ձորակ',
    'սեղան',
    'խնամի',
    'բաժակ',
    'հատու',
    'դահիճ',
    'ուժեղ',
    'աքցան',
    'միջակ',
    'լանջք',
    'տուրք',
    'դանակ',
    'մաճար',
    'տնքոց',
    'ծորակ',
    'փարոս',
    'գաւիթ',
    'կաղին',
    'հասու',
    'ափսոս',
    'գրպան',
    'կաւիճ',
    'կատու',
    'քամակ',
    'փեթակ',
    'նստիլ',
    'ճաշել',
    'կաղնի',
    'շառափ',
    'ծիծաղ',
    'դժոխք',
    'կօշիկ',
    'դրացի',
    'կապել',
    'շողալ',
    'անվախ',
    'շորոր',
    'լայնք',
    'կոպիճ',
    'քաղաք',
    'հաշիւ',
    'գիտակ',
    'բարիք',
    'եզակի',
    'թափել',
    'ճողել',
    'անչափ',
    'գնում',
    'սատակ',
    'աքլոր',
    'դիմել',
    'առողջ',
    'մատիտ',
    'սկիւռ',
    'չթթոց',
    'խաւար',
    'ձձում',
    'ուստի',
    'քոսոտ',
    'կոճակ',
    'անգամ',
    'յաւէտ',
    'նշխար',
    'հաճոյ',
    'դժգոհ',
    'կռնակ',
    'ուրիշ',
    'շօշափ',
    'նուէր',
    'ծամել',
    'դուրս',
    'տածել',
    'տաւիղ ',
    'ծալել',
    'կարաս',
    'ուռիլ',
    'պտտիլ',
    'գոչել',
    'պապակ',
    'քաղել',
    'նորէն',
    'գնդակ',
    'կաթիլ',
    'ճակատ',
    'կաշառ',
    'ցամաք',
    'չուան',
    'շտկել',
    'անուս',
    'խմիչք',
    'մրցիլ',
    'բժիշկ',
    'աղքատ',
    'մշուշ',
    'պիտակ',
    'հարաւ',
    'վրձին',
    'մզկիթ',
    'թուղթ',
    'շշիւն',
    'փախիլ',
    'փեսայ',
    'հրաշք',
    'զատել',
    'ծունկ',
    'թանաք',
    'քակել',
    'դդում',
    'սառիլ',
    'ռունգ',
    'չքնաղ',
    'ծովափ',
    'ոստրէ',
    'բռնել',
    'գմբէթ',
    'երկիր',
    'մայոց',
    'բրինձ',
    'բերան',
    'վահան',
    'շաղել',
    'պալատ',
    'անհատ',
    'վիճակ',
    'պատիւ',
    'արծաթ',
    'մետաղ',
    'ճառել',
    'կտրել',
    'արմաւ',
    'ճաշակ',
    'պահակ',
    'սփռոց',
    'քմայք',
    'դունչ',
    'երինջ',
    'յիմար',
    'ժանիք',
    'օժտել',
    'ծանակ',
    'տապակ',
    'կեանք',
    'պոչատ',
    'որսալ',
    'հովիւ',
    'տարազ',
    'շլինք',
    'բզզոց',
    'համակ',
    'զգալի',
    'կրիայ',
    'ճագար',
    'հիւսք',
    'ոմանք',
    'պատիճ',
    'կամաց',
    'հագագ',
    'որեւէ',
    'դատել',
    'արիւն',
    'բոսոր',
    'կմախք',
    'դպրոց',
    'տիպար',
    'ականջ',
    'քոթոթ',
    'քացախ',
    'ցաքան',
    'ծառայ',
    'օգնել',
    'աղջիկ',
    'համար',
    'անհոգ',
    'գաւառ',
    'պարան',
    'պատել',
    'տարիք',
    'շաչել',
    'ծանօթ',
    'բոլոր',
    'փշրել',
    'տունկ',
    'առջեւ',
    'ծարաւ',
    'որկոր',
    'քամել',
    'կարիք',
    'հատիկ',
    'մելան',
    'խրթին',
    'կոպիտ',
    'պարապ',
    'թրջել',
    'փական',
    'ճիւաղ',
    'փողոց',
    'շրջան',
    'անիկա',
    'շաղախ',
    'տօնել',
    'զուրկ',
    'հառաչ',
    'սիրել',
    'իշխան',
    'անկախ',
    'մունջ',
    'օրէնք',
    'պչրել',
    'հրթիռ',
    'սփռել',
    'հսկայ',
    'կեսար',
    'քծնիլ',
    'դպչիլ',
    'ողորմ',
    'ծակել',
    'նաւակ',
    'կարծր',
    'ըմպել',
    'քանակ',
    'անէծք',
    'քունք',
    'կանոն',
    'թիթեղ',
    'սալոր',
    'ընկեր',
    'կրկէս',
    'արժել',
    'ցօղել',
    'դեղին',
    'ոչինչ',
    'բողոք',
    'փետել',
    'կարագ',
    'բեկոր',
    'սնոտի',
    'առանց',
    'այսօր',
    'երգել',
    'թաթառ',
    'վայել',
    'շապիկ',
    'փաթիլ',
    'ուտել',
    'երանգ',
    'բարձր',
    'համով',
    'ճիրան',
    'անդամ',
    'սիսեռ',
    'ամուր',
    'խաշել',
    'թռչիլ',
    'ջրհոր',
    'ծախել',
    'չոքիլ',
    'սպանդ',
    'մինակ',
    'եզերք',
    'հազալ',
    'հարել',
    'վարիչ',
    'բարեւ',
    'գոմէշ',
    'նշմար',
    'մոծակ',
    'կանաչ',
    'այրել',
    'մասին'
]
