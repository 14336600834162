import { Cell } from '../grid/Cell'
import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const InfoModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title="Խաղի Կանոնները" isOpen={isOpen} handleClose={handleClose}>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Կռահէ Բառիկը 6 փորձերով։
      </p>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Ամէն մէկ փորձը պէտք է 5-տառնոց բառ մը ըլլայ։ Enter կոխէ որպէսզի փորձես այդ բառը։
      </p>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Ամէն մէկ կռահումէ ետք, տուփիկներուն գոյները կը փոխուին ցոյց տալու համար որքան մօտեցած ես պատասխանին։
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell
          isRevealing={true}
          isCompleted={true}
          value="շ"
          status="correct"
        />
        <Cell value="ե" />
        <Cell value="ր" />
        <Cell value="ե" />
        <Cell value="փ" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        շ տառը կանաչ է որովհետեւ ճիշդ տառ մըն է, ճիշդ տուփիկին մէջ:
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="թ" />
        <Cell
          isRevealing={true}
          isCompleted={true}
          value="ո"
          status="present"
        />
        <Cell value="ւ" />
        <Cell value="ղ" />
        <Cell value="թ" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        ո տառը դեղին է որովհետեւ ճիշդ տառ մըն է, բայց տուփիկը սխալ։
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="շ" />
        <Cell value="ա" />
        <Cell value="պ" />
        <Cell isRevealing={true} isCompleted={true} value="ի" status="absent" />
        <Cell value="կ" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        ի տառը մոխրագոյն է որովհետեւ այսօրուան Բառիկին մէջ ի չկայ։
      </p>

      <p className="mt-6 text-sm text-gray-500 dark:text-gray-300">
        Նոր Բառիկներ պիտի ըլլան ամէն օր։ Բարի վայելում։ 🙂
      </p>
    </BaseModal>
  )
}
