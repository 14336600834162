export const VALID_GUESSES = [
  'հտպիտ',
  'շաղել',
  'սթրել',
  'զեղել',
  'մսալի',
  'ծիծակ',
  'երկար',
  'գնչու',
  'անձեւ',
  'աղետի',
  'սոխակ',
  'հաւու',
  'խշշալ',
  'ճմթել',
  'բերրի',
  'տեսքը',
  'օսաքա',
  'նմուշ',
  'կուռք',
  'տեղայ',
  'թողեց',
  'կաղին',
  'թաւիշ',
  'հագել',
  'հոլթը',
  'տանէր',
  'բացիլ',
  'կրոնի',
  'մաշկի',
  'էդնան',
  'կաշառ',
  'կերայ',
  'արթօն',
  'խլիրդ',
  'գուաշ',
  'եզրակ',
  'կրակը',
  'գխտար',
  'արամն',
  'օժտած',
  'ականի',
  'պահակ',
  'գլխու',
  'էաբան',
  'կերաւ',
  'նարդի',
  'դիտեն',
  'գոցել',
  'խոփիկ',
  'կոկոս',
  'շչիւն',
  'ատոնց',
  'հպում',
  'վանքն',
  'քոյրը',
  'գրէին',
  'տենտի',
  'խոսել',
  'բազայ',
  'նիհար',
  'լեմոն',
  'նշեեն',
  'բեւեռ',
  'վայրն',
  'ցորեն',
  'քոլոզ',
  'հիմնէ',
  'աքլոր',
  'ծաղկի',
  'ջրվար',
  'թալակ',
  'օգուտ',
  'իսպառ',
  'սթեյք',
  'վիթել',
  'փողոտ',
  'զսպիչ',
  'թորիլ',
  'էսպէս',
  'քնարբ',
  'փորակ',
  'թքնել',
  'խաղին',
  'տկլոր',
  'խաչով',
  'երինջ',
  'ռեհան',
  'բողոճ',
  'ուզել',
  'վայոց',
  'սպորտ',
  'իրենը',
  'քմայք',
  'ղեկին',
  'ճաղար',
  'շահող',
  'արշին',
  'էգերը',
  'թաղել',
  'ձէթով',
  'սապատ',
  'մոդալ',
  'ջրտար',
  'փոխեց',
  'ծուէն',
  'ծագիլ',
  'աշուղ',
  'խաղիկ',
  'լծված',
  'լծկապ',
  'եփելէ',
  'գլուլ',
  'վարդի',
  'մետաղ',
  'կնիքի',
  'գնման',
  'շքերթ',
  'ոսկել',
  'դպնալ',
  'նշանի',
  'լոբու',
  'բազիս',
  'քաղաք',
  'ծորակ',
  'կղզիի',
  'արայի',
  'մթնիլ',
  'կիսել',
  'սուին',
  'մլուկ',
  'իրբեւ',
  'յոյսի',
  'չմփոց',
  'բռռոց',
  'կախիչ',
  'լացիք',
  'խելօք',
  'ղեղազ',
  'նեղիչ',
  'թէյէն',
  'կնճիթ',
  'կարսը',
  'շղարշ',
  'սեռէն',
  'պետին',
  'փաթոթ',
  'ֆշշալ',
  'կրծքի',
  'նանտի',
  'արեւի',
  'գրման',
  'շինող',
  'ժամին',
  'արինք',
  'թզկել',
  'սպասք',
  'փրկած',
  'կաթին',
  'շաւաղ',
  'մուտք',
  'լոկալ',
  'շղթան',
  'մրտիմ',
  'կտրել',
  'հոլով',
  'սնարք',
  'հեկել',
  'ջրսահ',
  'գծերն',
  'էլենի',
  'իրենձ',
  'յատակ',
  'գեյշա',
  'քիքին',
  'նստայ',
  'ուխայ',
  'հաւատ',
  'բառին',
  'կիսով',
  'արիայ',
  'տուրը',
  'սերոտ',
  'զնդան',
  'տռփոտ',
  'սիլաս',
  'ջրում',
  'հոռոմ',
  'ճաշել',
  'բևեռի',
  'գոչոց',
  'դուռն',
  'սամիր',
  'դորակ',
  'ոճերը',
  'խաշան',
  'ծառեր',
  'գաղթը',
  'շոուն',
  'սալոր',
  'գռփել',
  'ռենին',
  'հալոց',
  'ինկան',
  'խույս',
  'փալան',
  'տղաքը',
  'այրել',
  'մոտով',
  'գագար',
  'զիրար',
  'մաւեր',
  'դդմիլ',
  'արբած',
  'անօթի',
  'կողով',
  'նուաղ',
  'տողել',
  'գամմա',
  'անեզր',
  'բերին',
  'թաթիկ',
  'խազել',
  'կտրող',
  'սերիլ',
  'թերևս',
  'հարոս',
  'փողակ',
  'սնձնի',
  'իրանի',
  'ճլերք',
  'բիլլի',
  'պատիւ',
  'հորատ',
  'անհալ',
  'նոտար',
  'մեխակ',
  'իչհոն',
  'վնասէ',
  'գրողի',
  'բացեց',
  'զգաստ',
  'մեջքի',
  'նաղաշ',
  'հացիկ',
  'օքսիդ',
  'երգին',
  'բերքը',
  'մախոխ',
  'միմոս',
  'խաչեր',
  'օճախը',
  'առջեւ',
  'վազան',
  'ցցուի',
  'օծում',
  'ճտերը',
  'բջիջի',
  'անձրև',
  'խռթոց',
  'եռաչք',
  'քամիչ',
  'քոլիկ',
  'ժխտած',
  'նժոյգ',
  'վիճիլ',
  'սիբեխ',
  'չմպալ',
  'ոճում',
  'մոլոր',
  'մռռոց',
  'անպէտ',
  'լարել',
  'անվէճ',
  'իշխան',
  'սկսաք',
  'մժուժ',
  'չուտէ',
  'անտիկ',
  'ճոկան',
  'գմփոց',
  'ժամվա',
  'սլուլ',
  'առտու',
  'մօտէն',
  'կտրիճ',
  'սոցապ',
  'լափել',
  'շաղիլ',
  'թողիր',
  'ժահրի',
  'ժայռն',
  'գզում',
  'տղայի',
  'տոպազ',
  'դատին',
  'շենքն',
  'պաշաղ',
  'բինայ',
  'կույս',
  'հովիվ',
  'ցունց',
  'համակ',
  'դիմեց',
  'փակիլ',
  'կանեփ',
  'բուալ',
  'կպնել',
  'կարիճ',
  'թևերի',
  'փորձն',
  'գրախտ',
  'հողէն',
  'գաղձը',
  'նախնի',
  'մոդայ',
  'փնջել',
  'սրտին',
  'ուռաւ',
  'տոմատ',
  'մրցիլ',
  'ցանցը',
  'թանձր',
  'կրէկի',
  'ծխանի',
  'ձգանք',
  'ջիղին',
  'երկան',
  'լալեի',
  'ակնիկ',
  'գետին',
  'զոյգը',
  'հիւսն',
  'ծրտոտ',
  'թոռչի',
  'բոշայ',
  'ծլուլ',
  'դացին',
  'խոցեր',
  'տողան',
  'անալի',
  'ուրիշ',
  'չեզոք',
  'յետին',
  'չթթոց',
  'քաթան',
  'կըսէր',
  'լակել',
  'քերել',
  'փաշայ',
  'դեղձի',
  'փսիաթ',
  'անյագ',
  'բացիր',
  'թողեր',
  'քիսիկ',
  'քայլը',
  'զառիկ',
  'պտկիլ',
  'տռփալ',
  'ասսատ',
  'դենդի',
  'անցաւ',
  'մառան',
  'գագաթ',
  'հետեւ',
  'ջրարջ',
  'մխրճէ',
  'պարտք',
  'վսսալ',
  'ճռռան',
  'ըղձալ',
  'օճառի',
  'երկին',
  'դիպան',
  'մեկով',
  'ինչու',
  'կազով',
  'ֆալչի',
  'բլանդ',
  'հատիչ',
  'ռեյդի',
  'կապով',
  'իրերն',
  'թիթիզ',
  'կսկիծ',
  'քտնէլ',
  'զարդն',
  'գուղձ',
  'գրգիռ',
  'գովքը',
  'ասոլի',
  'թշշոց',
  'ազնիւ',
  'մղված',
  'տեսավ',
  'ճիլոպ',
  'հանաք',
  'անխաւ',
  'ծպպոց',
  'տզզալ',
  'գոյնը',
  'հողին',
  'մահիճ',
  'միակը',
  'ֆոնդը',
  'հազիլ',
  'ջոկել',
  'խառատ',
  'ամպիկ',
  'մեդիա',
  'ելնել',
  'լացաւ',
  'ընկած',
  'ցցվող',
  'դռներ',
  'հենած',
  'աթլաս',
  'բեմից',
  'ծերից',
  'նեխիչ',
  'վստահ',
  'անթիւ',
  'սատակ',
  'նկատի',
  'ամորֆ',
  'անջատ',
  'ճնշակ',
  'ողորկ',
  'էդտեղ',
  'միտէր',
  'ուսած',
  'այլևս',
  'բորան',
  'վառած',
  'ճեպով',
  'հուշտ',
  'վագոն',
  'ձյուն',
  'թեւակ',
  'սեղան',
  'օրէնք',
  'սնոտի',
  'ոսկու',
  'ճնկոց',
  'դիզել',
  'քացախ',
  'խռթալ',
  'փորձի',
  'թմբիկ',
  'սրինգ',
  'էանալ',
  'թախիծ',
  'գազից',
  'ծուփք',
  'որքան',
  'հրթիռ',
  'թնկոց',
  'հիրիկ',
  'չքմեղ',
  'արժան',
  'իզուր',
  'հոսկէ',
  'ցայլք',
  'հառաչ',
  'արշաւ',
  'խնդիր',
  'դիմած',
  'կղզու',
  'գազար',
  'հիպատ',
  'օրգան',
  'քամպօ',
  'գոռեխ',
  'ճլուզ',
  'արդէն',
  'պնդեց',
  'խռռալ',
  'գիտէի',
  'դիետա',
  'տասնի',
  'գետեր',
  'գռռոց',
  'ուղիի',
  'հեքիմ',
  'ուժին',
  'վազքի',
  'հատող',
  'ծուոց',
  'խորով',
  'մուխի',
  'վահան',
  'պայիկ',
  'տանձի',
  'բեհեզ',
  'եղինջ',
  'մնտել',
  'անգեղ',
  'ցնդիլ',
  'տօննէ',
  'խոտէն',
  'փորել',
  'գրքից',
  'հնչող',
  'սրտիք',
  'գլանի',
  'կոպալ',
  'արկեր',
  'ծռում',
  'իշուկ',
  'հովիկ',
  'թխպած',
  'աճուկ',
  'կոկոզ',
  'փայլի',
  'կաւով',
  'իշխած',
  'լոյսի',
  'լոպար',
  'ճէյմս',
  'քաշըը',
  'հարցի',
  'կաճեն',
  'նոպայ',
  'տարիի',
  'բույն',
  'կեքսի',
  'գողեր',
  'նորեկ',
  'շուէտ',
  'օրերի',
  'թաւայ',
  'կոծել',
  'ասդին',
  'հասած',
  'պզտիկ',
  'անոտք',
  'տաւար',
  'վախէն',
  'հասակ',
  'ձկներ',
  'ունակ',
  'սազիլ',
  'մկկալ',
  'հոնեղ',
  'քնկոտ',
  'տեղակ',
  'թաղին',
  'շաբաշ',
  'հոգայ',
  'աղբոտ',
  'անդիկ',
  'ոսկին',
  'գովել',
  'գինու',
  'հասաս',
  'անողի',
  'հատած',
  'անճէտ',
  'դժնիկ',
  'անճաշ',
  'էպոլա',
  'խորին',
  'զտուի',
  'րոպէի',
  'փաշան',
  'լոյսը',
  'կաշին',
  'ճնարը',
  'վռչոց',
  'հերան',
  'մատաղ',
  'կոծոծ',
  'նշոյլ',
  'միաթի',
  'սուաղ',
  'բոյսի',
  'հալաւ',
  'պիեսը',
  'ջնջիչ',
  'դնչիկ',
  'տեւեն',
  'լակոտ',
  'եղանք',
  'կակաօ',
  'դրոշը',
  'ցիտատ',
  'խչմար',
  'լարիչ',
  'խաւար',
  'հիմար',
  'կրպակ',
  'օրերն',
  'վարդը',
  'գինով',
  'գրքին',
  'ֆօտոյ',
  'խտրել',
  'պուէտ',
  'քասախ',
  'ժանրի',
  'ձորակ',
  'քանակ',
  'ջրչոր',
  'խավեր',
  'ձիերը',
  'փթահը',
  'դասած',
  'տարոն',
  'պնդիչ',
  'ընծայ',
  'աթենն',
  'մահով',
  'դնդեղ',
  'ռուսը',
  'աղիքի',
  'վառիլ',
  'ողբեր',
  'խնդրէ',
  'ռինգը',
  'սկսար',
  'դուետ',
  'վտանգ',
  'տանին',
  'փոսեր',
  'կաղալ',
  'մեղու',
  'բովել',
  'ծագող',
  'հոտով',
  'պատվո',
  'ճմռել',
  'կանչէ',
  'գրվել',
  'ավազը',
  'գզրար',
  'պիեսի',
  'նիզար',
  'տեսնի',
  'երեսը',
  'ջնջել',
  'դանիա',
  'կռում',
  'բողոք',
  'ճարել',
  'գղձիկ',
  'դստեր',
  'նորիք',
  'գիտեք',
  'յամառ',
  'սատին',
  'անթեւ',
  'յուղի',
  'զաւկի',
  'լեզու',
  'տորթը',
  'հարայ',
  'մնջոտ',
  'մողէս',
  'ցարին',
  'մուրը',
  'թաղիք',
  'մարզն',
  'լիճին',
  'սլենգ',
  'յոյսը',
  'ծուաղ',
  'բոճակ',
  'ակռայ',
  'յիշոց',
  'ափերի',
  'աղինճ',
  'ստաշխ',
  'դրոշմ',
  'տեւեց',
  'կազմը',
  'ոզնիկ',
  'լպիրշ',
  'հալալ',
  'փշուտ',
  'ներսի',
  'դիտէր',
  'արջառ',
  'իդիշի',
  'ուզած',
  'բերէր',
  'շինեց',
  'ոտմաշ',
  'կեռիլ',
  'փափաք',
  'հայիլ',
  'դայակ',
  'պարիկ',
  'քղորն',
  'լոյսն',
  'անհաճ',
  'հարար',
  'ջեռակ',
  'ֆանդի',
  'սպիրտ',
  'թեմայ',
  'կայսր',
  'լամոն',
  'ճպուռ',
  'թրթիռ',
  'անքան',
  'մգդակ',
  'զիջել',
  'հալէպ',
  'թմբել',
  'գացած',
  'շեղիլ',
  'գռփիչ',
  'տոներ',
  'քանդէ',
  'խորոզ',
  'նանիկ',
  'քաղքի',
  'հակակ',
  'խաչել',
  'վարսք',
  'տնանկ',
  'թամբը',
  'ջրհաւ',
  'ստորի',
  'ռապախ',
  'սրբել',
  'բխկոց',
  'անդէպ',
  'նորոգ',
  'արվել',
  'խոզան',
  'լիմպա',
  'քաշով',
  'տունկ',
  'ամփոփ',
  'կայիր',
  'այլոց',
  'ջրխոտ',
  'բոզեի',
  'բաժակ',
  'նորաճ',
  'տկռիլ',
  'մատակ',
  'սափոր',
  'կոստղ',
  'միրաբ',
  'ամեայ',
  'դարեր',
  'շոճիի',
  'գիտէր',
  'ներէն',
  'ճեղքը',
  'տորթի',
  'քովին',
  'խոպան',
  'չորսը',
  'սարեկ',
  'տառեղ',
  'ադոնց',
  'բարդի',
  'սրբոց',
  'լամել',
  'գեղմն',
  'դրդեց',
  'տիպին',
  'խոտան',
  'բարեւ',
  'ստլիկ',
  'աստղի',
  'մասից',
  'մէկով',
  'պրուս',
  'ճակատ',
  'լևինը',
  'զրկել',
  'բոլոր',
  'կտտոր',
  'ականջ',
  'թանչը',
  'ասվել',
  'ղժժոց',
  'աղանձ',
  'ասուի',
  'հրկէզ',
  'արված',
  'նուան',
  'կոխան',
  'թռչնի',
  'շինեն',
  'չափել',
  'խթում',
  'լիճեր',
  'պիցցա',
  'կեսար',
  'թքանք',
  'դեպքն',
  'հըլու',
  'նորել',
  'ուսել',
  'առիթի',
  'կապիչ',
  'էֆեկտ',
  'դէսից',
  'խաւոտ',
  'պժգալ',
  'ոճերի',
  'ձիագի',
  'ճշտիւ',
  'հոսող',
  'պշկել',
  'աաջին',
  'զարան',
  'օվալի',
  'ճաքիլ',
  'ամրան',
  'թնչալ',
  'նկարն',
  'թուիլ',
  'սրվել',
  'թռչել',
  'պարեկ',
  'էյմսի',
  'անսին',
  'խտրոց',
  'հօրով',
  'քչերը',
  'մոլոշ',
  'հոյթէ',
  'դեղել',
  'տիրան',
  'արժէք',
  'գօտին',
  'կիշխէ',
  'մէկէն',
  'դէմքի',
  'ոջլոտ',
  'թակել',
  'ծախել',
  'կիսող',
  'բարդը',
  'ազգին',
  'կրթել',
  'ելքեր',
  'թխսել',
  'լոկին',
  'հրշէջ',
  'սնձան',
  'բաւիղ',
  'կտորի',
  'այծիկ',
  'առում',
  'քաշեն',
  'հերթը',
  'նուալ',
  'ժպտիր',
  'վուդը',
  'դառնա',
  'գրիչի',
  'ցաւիլ',
  'թանաք',
  'առակը',
  'խախաց',
  'լքեալ',
  'դինար',
  'խնձոր',
  'դարէն',
  'պատմէ',
  'բռնկի',
  'գորիս',
  'ձմռան',
  'առոյգ',
  'կշռակ',
  'տեսնէ',
  'շաղիչ',
  'խժում',
  'էստոն',
  'երթալ',
  'աւազը',
  'իւղով',
  'կատիկ',
  'դոփել',
  'հատիկ',
  'կըսեն',
  'գրչին',
  'տոնին',
  'լաւայ',
  'ջրցեխ',
  'լավաշ',
  'այգիի',
  'հանձն',
  'չամիչ',
  'բխում',
  'չգտան',
  'լծկան',
  'անշեղ',
  'անաչք',
  'կունդ',
  'աւարի',
  'ճմլել',
  'շարքն',
  'նորէն',
  'օրեայ',
  'դիտակ',
  'ատիկա',
  'մրրիկ',
  'լանջի',
  'քոսոտ',
  'նայթը',
  'գզրոց',
  'ցանել',
  'զէնքի',
  'խժժոց',
  'լիզան',
  'նստիք',
  'իջնել',
  'բանան',
  'ծնողք',
  'շուշի',
  'տիսքօ',
  'անյան',
  'նստաւ',
  'կրում',
  'սադաֆ',
  'աւարտ',
  'գլխին',
  'ծաղիկ',
  'խնոցի',
  'աղտոտ',
  'շիթիլ',
  'ճզմել',
  'շերտի',
  'օդում',
  'սոթոտ',
  'լեզգի',
  'օգնեն',
  'ռադիո',
  'ձաւար',
  'մոմոտ',
  'ջնջոց',
  'խունկ',
  'պղտոր',
  'ազդեց',
  'ասանկ',
  'դանակ',
  'անձին',
  'ֆշշան',
  'մոգել',
  'մղվող',
  'ուշիմ',
  'ձիւնի',
  'խաժկն',
  'միջել',
  'գևորգ',
  'բրդօշ',
  'մերժէ',
  'թարէք',
  'շփերթ',
  'ջրպետ',
  'եղալի',
  'մեկնի',
  'տանիք',
  'եռում',
  'սկսէր',
  'փօլիօ',
  'տոկոս',
  'ջրքամ',
  'կուլի',
  'ստեղն',
  'հիմքի',
  'նովել',
  'հարող',
  'պոպոզ',
  'ձիթոտ',
  'ժամից',
  'ցցված',
  'պճռել',
  'անհամ',
  'քերիչ',
  'չափեր',
  'լճակն',
  'սորիլ',
  'խցկել',
  'կնկայ',
  'իմաստ',
  'խօսած',
  'առակի',
  'բանկի',
  'զօդել',
  'քոլեջ',
  'խօսին',
  'ազդրի',
  'աքցան',
  'եռաձի',
  'թպպալ',
  'տանից',
  'ճիւղը',
  'տային',
  'բաներ',
  'բույր',
  'բաժնէ',
  'գրգոտ',
  'տեղեր',
  'սոված',
  'ծխնիկ',
  'քաղիկ',
  'իրիսը',
  'խպիպի',
  'սուրբ',
  'պանիր',
  'որոնց',
  'ճմլոց',
  'կօգնէ',
  'փաստը',
  'պոկել',
  'ինջու',
  'աբուռ',
  'ինչից',
  'իդիոմ',
  'ֆրանք',
  'խժռոց',
  'խալիֆ',
  'գագիկ',
  'պնդեր',
  'լիրիկ',
  'ձերին',
  'բոկեղ',
  'արկղը',
  'էյսան',
  'ցեղից',
  'սեւադ',
  'աւագն',
  'այտիկ',
  'հուլա',
  'շախից',
  'ոռնոց',
  'պատժի',
  'օրհնի',
  'գմբէթ',
  'կտակը',
  'խրձել',
  'արթեն',
  'մասեր',
  'սիմակ',
  'խեցատ',
  'բառից',
  'մաղախ',
  'պտուտ',
  'դրոյթ',
  'պնդեն',
  'ապակի',
  'գիրով',
  'շողակ',
  'ջխտել',
  'ճշրիտ',
  'սոճին',
  'վճռել',
  'ճանկը',
  'ամայի',
  'վարկը',
  'լողան',
  'պեպէն',
  'ողերն',
  'մտնել',
  'շառափ',
  'ականթ',
  'ծամել',
  'բռնչի',
  'շտաբը',
  'նմանի',
  'անգին',
  'հօրմէ',
  'թյուն',
  'կողմն',
  'արքայ',
  'կէտեր',
  'ռեժիմ',
  'մարտը',
  'պաճիճ',
  'էտեղի',
  'դերեը',
  'լողալ',
  'հսկայ',
  'չստիկ',
  'անեղծ',
  'խածել',
  'հուրի',
  'չգտնի',
  'վզկապ',
  'աթինա',
  'խաշիլ',
  'անսազ',
  'մժեղը',
  'շաղոտ',
  'քշտել',
  'նետած',
  'խմիչք',
  'դհոլի',
  'տաջիկ',
  'համին',
  'տուիք',
  'պարող',
  'առկայ',
  'սաժեն',
  'չլինի',
  'խոռել',
  'աղիւս',
  'աղանդ',
  'սկում',
  'խաղաց',
  'լիմոն',
  'բղղոց',
  'խտտել',
  'հոկեյ',
  'որրան',
  'փարատ',
  'զնգոց',
  'չոչել',
  'կիբեր',
  'աղէտի',
  'փախել',
  'ընկաք',
  'խոհեր',
  'բրդել',
  'կացել',
  'ետևից',
  'բղղալ',
  'հոսել',
  'հեծել',
  'կիներ',
  'վոլոֆ',
  'սիպահ',
  'էրճաս',
  'յղում',
  'փշեայ',
  'ծղրիդ',
  'բաբան',
  'իյնայ',
  'նավով',
  'դժոխք',
  'ափկից',
  'փռում',
  'փայել',
  'փախաւ',
  'լոնգի',
  'դողիկ',
  'դրօշի',
  'ծնկան',
  'մոտիկ',
  'առանց',
  'ցանոտ',
  'դժխեմ',
  'փաթեթ',
  'շերտը',
  'անթիվ',
  'այրու',
  'օգտին',
  'թոռոմ',
  'գերիչ',
  'անէծք',
  'քունի',
  'սղոցի',
  'կէսօր',
  'կուպր',
  'պակաս',
  'խոթել',
  'բիթրի',
  'պարեր',
  'թեյեր',
  'թշվառ',
  'վազող',
  'չիլիկ',
  'դրանց',
  'երազը',
  'թօզոտ',
  'լճում',
  'հասան',
  'տափակ',
  'աշխէտ',
  'ծուիչ',
  'էնտեղ',
  'խյուս',
  'տաւիղ',
  'քորել',
  'ախորժ',
  'գացին',
  'էշարք',
  'արուն',
  'օրիայ',
  'ասլան',
  'մրուր',
  'փափախ',
  'լոնգը',
  'քալել',
  'կարծե',
  'առնէտ',
  'գոգել',
  'կռճօն',
  'սիրտի',
  'ավագի',
  'ուսից',
  'լինէր',
  'սերել',
  'խմելէ',
  'ձգված',
  'խմորի',
  'օջախն',
  'գանգը',
  'փորիչ',
  'ջհուդ',
  'պիլկի',
  'կմախք',
  'զավակ',
  'էջերը',
  'քույր',
  'զէնքը',
  'բլրոտ',
  'խնչոց',
  'ախպէր',
  'քիրազ',
  'փքում',
  'թրքոտ',
  'ցնցել',
  'շափաղ',
  'բալամ',
  'ամսին',
  'շլդիկ',
  'բոյնը',
  'լքում',
  'զնգալ',
  'կտցել',
  'ծակոց',
  'ջամբէ',
  'քիվին',
  'արարք',
  'դակիչ',
  'ալիան',
  'թվերի',
  'շնչատ',
  'տոտիկ',
  'անթեփ',
  'անինք',
  'զօրքը',
  'կատվի',
  'խժժալ',
  'տակից',
  'ցեղեր',
  'թիւեր',
  'քստոտ',
  'սիրեն',
  'գացիր',
  'օրերս',
  'թխման',
  'վիտէօ',
  'հրձիգ',
  'գմփիլ',
  'քանոն',
  'յոպոպ',
  'գերել',
  'ամլիկ',
  'ինչին',
  'կառնէ',
  'ցօղիկ',
  'երդիկ',
  'քորիչ',
  'յարնէ',
  'անծոտ',
  'դղորդ',
  'դդմակ',
  'օրհաս',
  'օրինի',
  'ափսէի',
  'իրէնց',
  'դասակ',
  'լարած',
  'առաջք',
  'ֆռռալ',
  'թաղած',
  'զոպայ',
  'փետել',
  'այրած',
  'փական',
  'ատոնք',
  'ռիսկը',
  'ապառք',
  'արշակ',
  'շալկէ',
  'գնվել',
  'թղթին',
  'քջուջ',
  'ֆագոտ',
  'գունդ',
  'հյուր',
  'պաշար',
  'գորքի',
  'անմեղ',
  'մաշան',
  'խաղան',
  'սնչիկ',
  'ջանայ',
  'թոթով',
  'քարոզ',
  'բոցել',
  'թամահ',
  'պալատ',
  'ջնջեց',
  'տեսան',
  'դպրաց',
  'քուրդ',
  'խօսիմ',
  'խորիզ',
  'ծարաւ',
  'բլթակ',
  'խաղայ',
  'ներսը',
  'բանալ',
  'վառեն',
  'իւղոտ',
  'ափերն',
  'ղաչաղ',
  'գալու',
  'իրմով',
  'օսլայ',
  'ակնայ',
  'վերստ',
  'ջահեր',
  'բերել',
  'քլորը',
  'պրկել',
  'ուժակ',
  'լեարդ',
  'լաչառ',
  'մորթը',
  'թորիչ',
  'ուրկէ',
  'կրօնն',
  'երազէ',
  'այրող',
  'ապչու',
  'ղրիմի',
  'խորոտ',
  'սկիզբ',
  'ցավեր',
  'հանիկ',
  'կանչը',
  'աթէնք',
  'հրշեջ',
  'յետոյ',
  'ջրփոս',
  'ծեծել',
  'ապրող',
  'այսպէ',
  'կաճին',
  'կրցած',
  'փշրել',
  'զգոյշ',
  'նաւեր',
  'վարձք',
  'կրկէս',
  'պաննո',
  'ոտնել',
  'իրենց',
  'արխիվ',
  'բարու',
  'կետեր',
  'բարիի',
  'մերոյ',
  'ռաշիդ',
  'կարամ',
  'թրախկ',
  'ցեխոտ',
  'բոսոր',
  'հովեկ',
  'փաթիլ',
  'առինք',
  'ծունգ',
  'միջով',
  'վրացի',
  'թռնիլ',
  'աբբաս',
  'սրտոտ',
  'զենքը',
  'սահել',
  'ղալաթ',
  'բացաւ',
  'բակին',
  'ոունի',
  'ցուրտ',
  'սկզբը',
  'օազիս',
  'փառքի',
  'մխում',
  'փռէին',
  'նալել',
  'վերձը',
  'սլլան',
  'սաւան',
  'նուրբ',
  'գայլի',
  'անկամ',
  'զէնքն',
  'կեռիկ',
  'հոտից',
  'վառել',
  'գամփռ',
  'գչրակ',
  'ֆավնի',
  'սառիլ',
  'ճաւայ',
  'ննջիլ',
  'կարիք',
  'պահած',
  'զիջիլ',
  'թմրիլ',
  'կորած',
  'քատիս',
  'ոզնին',
  'զօրու',
  'կածան',
  'լրում',
  'օթեակ',
  'ձուղպ',
  'մնջիկ',
  'անցնի',
  'թեքիլ',
  'վճռեց',
  'ապրոն',
  'դիակը',
  'ոգիին',
  'կզգամ',
  'շարքը',
  'տիպար',
  'ղրփել',
  'սօսին',
  'ծռված',
  'խղճալ',
  'անմար',
  'պակտը',
  'բադիկ',
  'ծղխնի',
  'մթնել',
  'զուրկ',
  'բանէն',
  'սուգի',
  'անմազ',
  'սոմառ',
  'ցաւեր',
  'փորձը',
  'աւիւն',
  'հնձիչ',
  'գահին',
  'չանաք',
  'էմոյի',
  'ջրբեր',
  'նավից',
  'քելիի',
  'անտառ',
  'շփվել',
  'ձեւիչ',
  'ցոլակ',
  'մրգեր',
  'մաքրէ',
  'գուրզ',
  'յարկի',
  'հետևի',
  'խորակ',
  'մայոց',
  'աշոտը',
  'փարախ',
  'դեկան',
  'արձակ',
  'եղծել',
  'թեմէն',
  'բնկալ',
  'կարդա',
  'կտուր',
  'նւագէ',
  'կառքը',
  'սոնետ',
  'ինչեր',
  'հարգի',
  'սիկհը',
  'հեյսը',
  'նորին',
  'լուծը',
  'ծախած',
  'ճպռոտ',
  'միջոց',
  'ածանց',
  'լացիր',
  'պոեմն',
  'փրկել',
  'մղվել',
  'դրսեկ',
  'պղնձէ',
  'մագիլ',
  'շերամ',
  'երկիր',
  'դեպքի',
  'աստառ',
  'կեղևի',
  'գրողն',
  'ըսածը',
  'բռնած',
  'ֆոլիի',
  'ճիճու',
  'անվար',
  'փաքսի',
  'ճկռել',
  'յօշել',
  'փոճոկ',
  'մկանը',
  'ասպետ',
  'կորով',
  'ապրիմ',
  'պչրել',
  'թշշալ',
  'գուֆը',
  'ծնված',
  'կենաց',
  'աղքատ',
  'սղկել',
  'լինիլ',
  'սոֆիա',
  'իրերի',
  'մարկը',
  'թողած',
  'շուրթ',
  'չեչոտ',
  'աւելի',
  'նաւազ',
  'չափիչ',
  'սկսիլ',
  'գցում',
  'մղմեղ',
  'ճաղել',
  'մաճար',
  'ալրոտ',
  'գոտին',
  'փշուր',
  'սագիկ',
  'պատառ',
  'դիզեց',
  'փողից',
  'պապին',
  'ժանսը',
  'աբասի',
  'շարքի',
  'կարոն',
  'ըլլար',
  'լսում',
  'երգիչ',
  'ծովափ',
  'հիւթը',
  'ֆետիշ',
  'անբեղ',
  'սորոր',
  'բղխել',
  'ծխչոր',
  'կայքի',
  'լծուի',
  'ցցհար',
  'չղջիկ',
  'մռայլ',
  'արշտա',
  'կսմիթ',
  'պոտաշ',
  'բեկոր',
  'շիշակ',
  'կետին',
  'ֆրթոց',
  'երազս',
  'մղորդ',
  'սապոն',
  'ձգելի',
  'նուշը',
  'տիարա',
  'գուռզ',
  'բառով',
  'ձկնիկ',
  'գոմել',
  'ամպեր',
  'աթոռը',
  'կաշիէ',
  'կարծր',
  'փօփին',
  'սպանդ',
  'կշռոց',
  'լոսոց',
  'անշող',
  'տրոլը',
  'արմաւ',
  'վճռած',
  'դունչ',
  'դասել',
  'վէզիր',
  'ժանիք',
  'փլչել',
  'սուէզ',
  'տողէն',
  'ասում',
  'պահից',
  'տեմպը',
  'մեխեց',
  'փառքն',
  'սիւժէ',
  'երկաթ',
  'խնդալ',
  'ջունի',
  'ուռիլ',
  'եղնիկ',
  'հողեր',
  'վագրը',
  'խրիմի',
  'գազել',
  'զինքի',
  'փիծծա',
  'վսսոց',
  'նստար',
  'հռինդ',
  'բլոգը',
  'շալախ',
  'ետեւը',
  'տիոնի',
  'տները',
  'ճագար',
  'վարակ',
  'տեմպե',
  'քթթել',
  'քաւել',
  'խռռոց',
  'վավեր',
  'նշխար',
  'բրդեա',
  'հոգալ',
  'ջրարբ',
  'ածելի',
  'դաշան',
  'բերիք',
  'սովետ',
  'հատեն',
  'ծակել',
  'տեղէն',
  'վոկալ',
  'հոգան',
  'խմելը',
  'երգած',
  'յանցն',
  'նուազ',
  'հաբէշ',
  'մեղքի',
  'բայից',
  'ժայռի',
  'չափէն',
  'գրկել',
  'բաթաթ',
  'սռնակ',
  'առնաս',
  'սաղմի',
  'ինտիմ',
  'սահքի',
  'անանկ',
  'հոսքի',
  'ֆիլմն',
  'ծծկան',
  'սեռին',
  'սիրեր',
  'լլկել',
  'ցնծալ',
  'մաղձի',
  'ակրատ',
  'վարիչ',
  'տանող',
  'մասրե',
  'վիրապ',
  'վախել',
  'շփում',
  'մահիկ',
  'փալաս',
  'թերաճ',
  'ղարիբ',
  'հետքն',
  'խմբից',
  'վերաճ',
  'սեւակ',
  'տղմոտ',
  'գիտեմ',
  'սալոն',
  'ծախսէ',
  'տողով',
  'սրահէ',
  'թերթն',
  'հոբբի',
  'ծրարը',
  'ճոլին',
  'քուսպ',
  'մոկքի',
  'աբբայ',
  'խաչիկ',
  'մկրատ',
  'կաւատ',
  'բրնձի',
  'շորոր',
  'ոսկրի',
  'շլինք',
  'բակից',
  'իշխեց',
  'գոմէշ',
  'նօրէն',
  'սահիլ',
  'տաշեղ',
  'շիջիլ',
  'տօնով',
  'տափան',
  'կոանք',
  'պիեսն',
  'ջրցան',
  'վժժան',
  'կտուց',
  'փայտե',
  'կոզակ',
  'յղացք',
  'քչերն',
  'գացիք',
  'օրերը',
  'քցում',
  'խռովք',
  'կույտ',
  'նավթն',
  'կռվեց',
  'սառաւ',
  'էկրան',
  'կճպել',
  'թակած',
  'ճէյմի',
  'ապակե',
  'սղում',
  'քներգ',
  'գնդել',
  'քուրտ',
  'փակոց',
  'յետեւ',
  'մէջքը',
  'գաջել',
  'ծուիլ',
  'քունք',
  'գոհար',
  'խաթար',
  'մղիչը',
  'նշենք',
  'կլիշէ',
  'սիրեմ',
  'ուսաւ',
  'օգնեց',
  'դրոյք',
  'կայքն',
  'ոտքին',
  'յստակ',
  'էդքան',
  'շաբաթ',
  'բրուտ',
  'շալակ',
  'բտում',
  'տեխնո',
  'կրիկի',
  'ֆսսոց',
  'խոխոջ',
  'անուշ',
  'ջանալ',
  'որմով',
  'անքար',
  'ծնգիլ',
  'մակետ',
  'բլուլ',
  'թխէին',
  'շարֆի',
  'հրիայ',
  'շաղախ',
  'ծանօթ',
  'ովեւէ',
  'մահակ',
  'ուղին',
  'նրբին',
  'հօտաղ',
  'վառող',
  'ծախքը',
  'ջիւան',
  'գրենք',
  'գինիի',
  'ջլախտ',
  'կաճառ',
  'ծիրէն',
  'ռոքին',
  'քաքաօ',
  'ըմպան',
  'պաղիչ',
  'ճոռիկ',
  'քոչոր',
  'կամաց',
  'առթիվ',
  'խշիւն',
  'ճապաղ',
  'ցանիչ',
  'խաշին',
  'պաչիկ',
  'արտեր',
  'անխաշ',
  'մօտիկ',
  'միջեվ',
  'էստեղ',
  'յովազ',
  'անտոք',
  'մղելէ',
  'բնոյթ',
  'տօներ',
  'ջրկից',
  'քալեն',
  'թիքայ',
  'թունդ',
  'լցոնը',
  'անծալ',
  'ըլլալ',
  'ծոփոր',
  'նշէին',
  'ռազմի',
  'ծունկ',
  'բիթիի',
  'լիցքը',
  'կեղեւ',
  'խիզան',
  'անշահ',
  'զրկած',
  'թերթի',
  'գանձը',
  'գոյժը',
  'կրօնք',
  'թրջեն',
  'խոսքն',
  'մժղիկ',
  'զարնէ',
  'կարող',
  'ռամիկ',
  'ալոկն',
  'շահին',
  'փուռի',
  'քարից',
  'ահալի',
  'պարան',
  'բրինձ',
  'առիւծ',
  'հեռու',
  'նկուն',
  'յենած',
  'կրիան',
  'փոխան',
  'դեպքը',
  'նախօր',
  'անկախ',
  'խաւեր',
  'դողալ',
  'անայգ',
  'բանչը',
  'մեթոդ',
  'դիմաց',
  'վուշէ',
  'վարքն',
  'անծախ',
  'զենիթ',
  'յուղը',
  'մեռել',
  'չուառ',
  'փոստի',
  'բադան',
  'խելքի',
  'անեցի',
  'որդու',
  'բնդիռ',
  'անշէն',
  'ցինկէ',
  'շուէդ',
  'ֆասադ',
  'սխտոր',
  'բրդոտ',
  'քոզառ',
  'եռեակ',
  'հրեայ',
  'նայած',
  'ըմբիշ',
  'հատակ',
  'յարգի',
  'անցան',
  'ատենը',
  'հողոտ',
  'տանկը',
  'դրախմ',
  'ինչով',
  'ծէսին',
  'օլգան',
  'գոչել',
  'էապես',
  'գդակը',
  'հագագ',
  'քայլք',
  'զսպան',
  'ուրախ',
  'ամոլը',
  'ջրերը',
  'տուտն',
  'նռնակ',
  'սատապ',
  'դասից',
  'նկատէ',
  'յէտոյ',
  'զինկը',
  'էջում',
  'ճառել',
  'թափառ',
  'դրդիչ',
  'շռինդ',
  'զխկիլ',
  'փշալի',
  'պտուկ',
  'փորձէ',
  'անգիր',
  'չիփակ',
  'էապէս',
  'նաւվը',
  'մանէթ',
  'լողափ',
  'կանոն',
  'ազոտի',
  'փերին',
  'թսսալ',
  'ամրոց',
  'ինչևէ',
  'դռնակ',
  'դէմքը',
  'ճաթիլ',
  'ոժերն',
  'քրջոտ',
  'կաւար',
  'իրենք',
  'հիւլէ',
  'ֆերմա',
  'էսսեն',
  'մեդալ',
  'անագը',
  'դահիճ',
  'քերած',
  'սքեմը',
  'դաւիլ',
  'աղուտ',
  'որպէս',
  'ինկող',
  'ախմախ',
  'սպասէ',
  'կիփսի',
  'ֆոբիա',
  'պայէր',
  'աւանս',
  'անագի',
  'ժողով',
  'շրջիլ',
  'ըսենք',
  'ոչինչ',
  'եզրեր',
  'գիտակ',
  'հատոր',
  'ղժղոց',
  'սածիլ',
  'պահքն',
  'կոկոն',
  'կոկետ',
  'հնարք',
  'գիւտը',
  'օթյակ',
  'էսքան',
  'եռալը',
  'շապիկ',
  'ունին',
  'գալար',
  'շաղափ',
  'բարձի',
  'իշխել',
  'բնում',
  'թուխս',
  'դմփոց',
  'յղկել',
  'հրաշք',
  'մինչեւ',
  'համառ',
  'ուտեն',
  'կռռալ',
  'քննել',
  'ծուան',
  'խոփեր',
  'դրամը',
  'ոստրէ',
  'նոյնը',
  'մաշիկ',
  'մեռած',
  'հինիչ',
  'քաշեց',
  'ճիպոտ',
  'խռչակ',
  'կիզել',
  'ճնշել',
  'ուժով',
  'մակաղ',
  'մելիք',
  'խշկիլ',
  'ջաղաց',
  'կռուի',
  'ձախող',
  'լճերը',
  'ծլթոց',
  'մակար',
  'արուի',
  'նորոն',
  'հովվի',
  'ճահիճ',
  'ամուր',
  'պիծակ',
  'անկոխ',
  'աքսոր',
  'գոլէճ',
  'մարդն',
  'դնէին',
  'գնդիկ',
  'վկայէ',
  'կիտել',
  'ոսկիի',
  'ծախեց',
  'պշում',
  'բլրիկ',
  'հծծել',
  'թվերը',
  'անդամ',
  'երգէր',
  'նայել',
  'կռինչ',
  'սրտով',
  'անցավ',
  'չուխա',
  'բոպիկ',
  'շշուկ',
  'խարաֆ',
  'ղազախ',
  'մնանք',
  'ասածի',
  'քրամպ',
  'բտիլն',
  'գերան',
  'կկզիլ',
  'կապող',
  'գիտեն',
  'կխտար',
  'կրծող',
  'տեքստ',
  'ստորև',
  'հմայք',
  'արժել',
  'վիլակ',
  'գները',
  'չնչին',
  'ձիրքը',
  'երբեք',
  'հարկէ',
  'ծնկոտ',
  'վէպեր',
  'կռթալ',
  'րոպեի',
  'կեղտը',
  'չուստ',
  'յարիլ',
  'շաղիկ',
  'ղրկեն',
  'դդում',
  'սուրը',
  'արմէն',
  'ոտքով',
  'ապուր',
  'թռչիլ',
  'շնչին',
  'սնման',
  'գիքրը',
  'եռաթի',
  'թեփոտ',
  'պոչիկ',
  'վագրն',
  'նստեց',
  'ճկոյթ',
  'սմբակ',
  'անթել',
  'աղացք',
  'բացից',
  'պարոս',
  'ուժատ',
  'թրչել',
  'ծախէր',
  'մտածէ',
  'հոլիկ',
  'սնդիկ',
  'սաղմը',
  'կատակ',
  'պիջակ',
  'ջրդեղ',
  'բեղիկ',
  'գացող',
  'խզզոց',
  'իջնող',
  'կռնակ',
  'հարցն',
  'փրկեց',
  'մուշկ',
  'գոլֆի',
  'գրբաց',
  'քնջիթ',
  'վասալ',
  'անրակ',
  'բերան',
  'քունգ',
  'կապառ',
  'խշտել',
  'ուժեղ',
  'կճճիկ',
  'կողքը',
  'ղշլաղ',
  'պահեր',
  'դարին',
  'բախտն',
  'գնդակ',
  'բակեր',
  'ժնգոց',
  'վզզոց',
  'դրամի',
  'սրանք',
  'մգլիլ',
  'գետնի',
  'հինգը',
  'մասէն',
  'տրամա',
  'խօսէր',
  'նժարը',
  'ագենտ',
  'նստին',
  'ումբր',
  'շխկալ',
  'դրվել',
  'յառաջ',
  'քոթակ',
  'գառան',
  'ազգէն',
  'մոծակ',
  'տոկայ',
  'շշերը',
  'օգնած',
  'ամենի',
  'անկոչ',
  'հալիմ',
  'մղում',
  'քաշել',
  'պայթի',
  'տառեր',
  'թեւիկ',
  'վշտոտ',
  'աքսել',
  'շիւան',
  'միջատ',
  'տեմբր',
  'փլում',
  'ղազան',
  'դնգոց',
  'շողիք',
  'նշելի',
  'արինճ',
  'խաղէն',
  'աչքէն',
  'ուղտի',
  'մգլած',
  'նավեր',
  'միւսը',
  'քրոջը',
  'լոռիի',
  'խնամք',
  'մտներ',
  'բուռն',
  'բուստ',
  'բլանկ',
  'անցած',
  'մահու',
  'ամոթը',
  'ալիքի',
  'հաջել',
  'խաշած',
  'նոխազ',
  'անխաչ',
  'արեթա',
  'հարել',
  'ղշլայ',
  'կավից',
  'ձեռաց',
  'կուրս',
  'շիրայ',
  'հոնկէ',
  'խլախտ',
  'ճաշակ',
  'հասաւ',
  'մասով',
  'ծրտել',
  'շիրիմ',
  'անժամ',
  'մարած',
  'աղբէր',
  'գամիկ',
  'մորֆի',
  'շասսի',
  'կոթոց',
  'ալեւի',
  'դաման',
  'միսով',
  'սեզոն',
  'տրվող',
  'բնդոց',
  'ցավոք',
  'հայեր',
  'չանաղ',
  'գիտես',
  'խեփոր',
  'անարգ',
  'ծլանք',
  'սեւաչ',
  'կայէն',
  'գինին',
  'փայտը',
  'հանել',
  'բոբող',
  'կերոն',
  'օնիքս',
  'դրդել',
  'մինաս',
  'թեւեր',
  'դրանք',
  'քարէն',
  'գտնայ',
  'ուշքի',
  'ֆիտիլ',
  'գիհիի',
  'համեմ',
  'պատիր',
  'քըշայ',
  'գոյնն',
  'պասիւ',
  'շրջէր',
  'ջղջիկ',
  'ցնցեց',
  'պտոյտ',
  'օհայո',
  'տոհմէ',
  'թակիչ',
  'հայոց',
  'եւրոն',
  'լեռան',
  'տեղալ',
  'անբառ',
  'սմքիլ',
  'փթռակ',
  'կոլոտ',
  'խլշել',
  'զղջալ',
  'ոմանց',
  'զիջիր',
  'նինան',
  'խռպոտ',
  'պաճոյ',
  'ծառայ',
  'շնորհ',
  'խոլոռ',
  'կօշիկ',
  'բլթել',
  'փափագ',
  'լոլիկ',
  'ամողջ',
  'բլուր',
  'հողան',
  'վարոց',
  'հսկել',
  'լաստը',
  'տրուի',
  'խորէն',
  'թափեն',
  'կխժռի',
  'թորած',
  'դուքս',
  'վերին',
  'աւանէ',
  'ցրման',
  'հարիչ',
  'վախով',
  'ներեր',
  'մթերք',
  'նշված',
  'նշուտ',
  'հանած',
  'կիթառ',
  'մտնեն',
  'չուան',
  'ջազից',
  'մայոր',
  'քուրք',
  'կուրծ',
  'խփում',
  'քէշան',
  'խթանէ',
  'հօրից',
  'ֆավնը',
  'ջլուտ',
  'լագան',
  'տակէն',
  'միհրը',
  'եղեամ',
  'աղտոր',
  'անծեփ',
  'տիրոջ',
  'ծակիչ',
  'արատի',
  'լմնալ',
  'ձէնով',
  'հալած',
  'լազեր',
  'ջրհոս',
  'շենքը',
  'սրակն',
  'կաւիճ',
  'ճարակ',
  'անթաղ',
  'լոքել',
  'ոստոտ',
  'ծառին',
  'աթոսի',
  'լիլիթ',
  'խմելի',
  'գիծով',
  'հողից',
  'կապել',
  'տեսել',
  'վայրի',
  'վիճին',
  'ռոկեղ',
  'ծնոտի',
  'նյութ',
  'ագոյց',
  'ծամոն',
  'եռուն',
  'թոշակ',
  'խղճին',
  'գավառ',
  'սլացք',
  'թևերը',
  'նազան',
  'ռաման',
  'ռեյնը',
  'պարկն',
  'պրծած',
  'սուշի',
  'վակաս',
  'փոքրը',
  'հնչել',
  'տօնած',
  'հրէշը',
  'քինոտ',
  'անձիք',
  'չքնել',
  'երբէք',
  'տիրած',
  'կակաո',
  'բնդեռ',
  'լցնել',
  'լոուն',
  'հաճիլ',
  'թխկոց',
  'դրդած',
  'ընկեր',
  'ջահէլ',
  'քիթին',
  'տպված',
  'ծէսէն',
  'թիւին',
  'դժժոց',
  'գետափ',
  'մեկու',
  'քակել',
  'վխտալ',
  'դռնից',
  'պարել',
  'պորտը',
  'բերքի',
  'ծիտիկ',
  'ինկաք',
  'սնանկ',
  'եզրիկ',
  'թաբեց',
  'ժանրը',
  'եղելէ',
  'շարեն',
  'բոռալ',
  'տալիք',
  'լապշա',
  'հաուս',
  'արեւը',
  'սխալի',
  'կնիքը',
  'հոտած',
  'լայիք',
  'աշուն',
  'տիպիկ',
  'քոսայ',
  'կռճիկ',
  'հազիւ',
  'թութք',
  'բալիկ',
  'վժժոց',
  'խրթին',
  'սադափ',
  'դիջեյ',
  'անցար',
  'եղերգ',
  'պատիճ',
  'աղգատ',
  'վանել',
  'դրացի',
  'նամոտ',
  'հաւաս',
  'մորթէ',
  'փակիչ',
  'բխկալ',
  'վարէն',
  'ստերջ',
  'անգամ',
  'իւղել',
  'ալբոմ',
  'յիշած',
  'պատին',
  'առողջ',
  'նայիլ',
  'գրգլի',
  'հաշիվ',
  'վարքը',
  'հոտիլ',
  'չերին',
  'կրօնը',
  'պապիկ',
  'հատել',
  'ասոնց',
  'նստիլ',
  'ազդած',
  'անչար',
  'հմուտ',
  'թրըմբ',
  'սողալ',
  'մէկնը',
  'փորոք',
  'մշուշ',
  'փակեն',
  'տնեցի',
  'յենել',
  'ֆշշոց',
  'ճլզել',
  'կրնալ',
  'քիմքի',
  'մեղքը',
  'գիրքի',
  'խոտոց',
  'ածովի',
  'ցանեն',
  'յարէր',
  'գետով',
  'պատվի',
  'պղնձի',
  'որսալ',
  'կլպել',
  'տիրող',
  'ասոնք',
  'անոնք',
  'զանգը',
  'տաճառ',
  'պրասն',
  'ցոլիլ',
  'ունիք',
  'ջրթափ',
  'պիպիչ',
  'փթթիլ',
  'մածիկ',
  'օրրան',
  'անձեռ',
  'բացել',
  'երաշտ',
  'բկլիկ',
  'կապար',
  'կնճիռ',
  'ձախից',
  'իւանը',
  'խոտով',
  'բրդյա',
  'թւում',
  'տարիք',
  'անիմե',
  'մկնիկ',
  'երգել',
  'ծածկէ',
  'գետակ',
  'միակն',
  'սլաքի',
  'պճղել',
  'ժահրը',
  'ճանճը',
  'սրում',
  'դմբոց',
  'անտաշ',
  'թեպետ',
  'պարոր',
  'ոտքից',
  'սպայի',
  'ուժեր',
  'սիանը',
  'երեւի',
  'շունը',
  'ընկել',
  'տարով',
  'գաւաթ',
  'լսելի',
  'ապագա',
  'շեշտն',
  'զիբիլ',
  'չարչի',
  'շահել',
  'ասորի',
  'խմորը',
  'տօնէն',
  'մոմով',
  'եղբօր',
  'արվեց',
  'աղբել',
  'թշշան',
  'ամբոխ',
  'հեզիկ',
  'պարեխ',
  'զարդը',
  'ծովեր',
  'շնդող',
  'կամքի',
  'գազեր',
  'սիրել',
  'աբխազ',
  'նեարդ',
  'կոլֆը',
  'կիսան',
  'ճառեր',
  'ճրճիմ',
  'նշմար',
  'քամին',
  'կենայ',
  'շրշոց',
  'պտռել',
  'կացաւ',
  'կեպիի',
  'սուտի',
  'թրքել',
  'առկող',
  'վիժակ',
  'ջրհոր',
  'հղկել',
  'աբսիդ',
  'ջլբաց',
  'մասոն',
  'վաֆլի',
  'փնչալ',
  'երկու',
  'մորին',
  'զեւսի',
  'խաղով',
  'անխօս',
  'մահից',
  'կրծօն',
  'պարապ',
  'հագին',
  'բոցոտ',
  'երթեր',
  'տոֆու',
  'նպաստ',
  'բուիճ',
  'տակով',
  'կռտել',
  'փեսայ',
  'կռիւը',
  'սառին',
  'կադրի',
  'գիլան',
  'սատափ',
  'ոսկրը',
  'կաթէն',
  'ճչիւն',
  'մռութ',
  'դնդալ',
  'բերեց',
  'համար',
  'չիման',
  'պարէն',
  'սլլալ',
  'սարքր',
  'թակոց',
  'նշտար',
  'խաժակ',
  'վճիռն',
  'կերել',
  'օծուն',
  'ռոմբի',
  'զրընգ',
  'քալած',
  'ատեան',
  'հավատ',
  'բուրգ',
  'փոշին',
  'մրկել',
  'մտոցը',
  'ակրան',
  'անփակ',
  'զօդիչ',
  'ջրերի',
  'թափան',
  'կատու',
  'հագած',
  'գոշան',
  'պատով',
  'բերչի',
  'գինոտ',
  'տեւէն',
  'ցմփոր',
  'ալիքն',
  'կտցար',
  'ճկում',
  'կախած',
  'սոտան',
  'ցփնել',
  'կաննի',
  'անսեռ',
  'յիշել',
  'վանիչ',
  'զօռել',
  'ընկավ',
  'ուղեղ',
  'փիարօ',
  'դրկից',
  'անկոփ',
  'թողել',
  'սիլոս',
  'ծառով',
  'ճղփոց',
  'չռթել',
  'փտնել',
  'մառեն',
  'թրջոց',
  'վժժալ',
  'խոսքը',
  'տուբը',
  'ժիհատ',
  'սենատ',
  'քեչայ',
  'ներքև',
  'եփված',
  'լիգայ',
  'ստեղի',
  'գալով',
  'միայն',
  'ամիսը',
  'վրանն',
  'հաչոց',
  'մեռնի',
  'սալաթ',
  'բանգի',
  'կացին',
  'ճահճի',
  'պտուղ',
  'զոհել',
  'ճաշեր',
  'պարէտ',
  'ողբալ',
  'գաղթի',
  'լիճէն',
  'նոճու',
  'քարել',
  'պրկոց',
  'մական',
  'գիծեր',
  'արձան',
  'շարժը',
  'աոանց',
  'բակէն',
  'աղերս',
  'որձակ',
  'փախան',
  'ամէնք',
  'զաթար',
  'ծագել',
  'նիւթի',
  'ցինիկ',
  'բլուզ',
  'կշիռք',
  'մագոգ',
  'ասիոյ',
  'ներսն',
  'վազել',
  'վնգալ',
  'թափիլ',
  'օպերա',
  'բաժան',
  'անճար',
  'բարձր',
  'դեմքը',
  'հաշիւ',
  'պէքոֆ',
  'սկսած',
  'կշռել',
  'հադին',
  'արնոտ',
  'զոլել',
  'շևենի',
  'կարագ',
  'մորեխ',
  'ունէր',
  'սլկել',
  'հայցը',
  'գրելէ',
  'թեփիկ',
  'ստանա',
  'չմքել',
  'թէյել',
  'սռռալ',
  'լիրան',
  'կճուճ',
  'շլացք',
  'հորել',
  'ցռուկ',
  'ալիքը',
  'պլանի',
  'սեւիկ',
  'կտրեց',
  'պապից',
  'գռռալ',
  'բանին',
  'այրիլ',
  'կառափ',
  'ֆայհա',
  'շարոց',
  'շունն',
  'մարել',
  'խաղաղ',
  'մարաղ',
  'կռվել',
  'խրդին',
  'մրսել',
  'սանիկ',
  'ծակոտ',
  'տուրք',
  'ատաղձ',
  'կրելն',
  'ծովու',
  'կրթած',
  'տեսքի',
  'ուռած',
  'կիրճի',
  'սեւրի',
  'մոնեն',
  'համեղ',
  'կրոնը',
  'ծորան',
  'գուալ',
  'տումի',
  'փախած',
  'ճայակ',
  'այսօր',
  'յօդին',
  'ղամիշ',
  'դիրքի',
  'չինար',
  'էթնիկ',
  'ցրտել',
  'փշշալ',
  'տամայ',
  'սաշան',
  'ածխոտ',
  'շիլայ',
  'նօթոտ',
  'բնտրէ',
  'տևում',
  'տզերը',
  'մայրն',
  'զսպող',
  'ամուլ',
  'անդակ',
  'ռեզին',
  'տեսած',
  'տնտես',
  'փոխեն',
  'ծլում',
  'ընտիր',
  'անանց',
  'հեծիկ',
  'հարկը',
  'բնազդ',
  'նօտար',
  'փսխել',
  'քարտե',
  'թթվով',
  'դրօշը',
  'դիզած',
  'խրկել',
  'նաֆթը',
  'լպիրծ',
  'շոլցը',
  'թողէք',
  'խոլոր',
  'համով',
  'փայտէ',
  'ծծուկ',
  'հաջող',
  'տաքսի',
  'ինկած',
  'ինչոց',
  'նիստն',
  'բայազ',
  'շեփոր',
  'դրված',
  'տիտիկ',
  'պապակ',
  'տղուն',
  'վռչալ',
  'ձայնն',
  'կռելի',
  'մենակ',
  'դիտել',
  'երգող',
  'վաւաշ',
  'պաչել',
  'զսպել',
  'մետալ',
  'շուար',
  'կռզու',
  'տհոլը',
  'հաճար',
  'տուկն',
  'ծփանք',
  'ջրվէժ',
  'հաճած',
  'բորակ',
  'ֆոլկի',
  'թրեպի',
  'սովին',
  'սերին',
  'կնկան',
  'հարկի',
  'հիւթի',
  'արքատ',
  'արկան',
  'ճկուն',
  'երիցս',
  'մեղքէ',
  'շվաբը',
  'անմաշ',
  'լքասը',
  'հռոմի',
  'թխենի',
  'փունջ',
  'խմբով',
  'պողոճ',
  'տուֆը',
  'արշավ',
  'բարիք',
  'զգում',
  'հույն',
  'քնձոտ',
  'ռայոն',
  'կաֆել',
  'խշշոց',
  'կռթոց',
  'կղղոց',
  'հթիռը',
  'մակաճ',
  'ռունգ',
  'աբրայ',
  'բալադ',
  'մարշը',
  'մեկից',
  'ծոցեր',
  'բառէն',
  'ծերատ',
  'ռետին',
  'քուրջ',
  'սիգար',
  'սալիկ',
  'նեխած',
  'կխլեն',
  'եղնար',
  'շուաք',
  'վեղար',
  'խմբեր',
  'բանար',
  'խուռն',
  'աթոռի',
  'իմանի',
  'կոտակ',
  'սկսեր',
  'փանաք',
  'քրքիջ',
  'ջրլից',
  'վառակ',
  'սփռոց',
  'չթեայ',
  'թույն',
  'դարափ',
  'ոսկիէ',
  'թմբիր',
  'մարաշ',
  'փչովի',
  'ուսէն',
  'նկարէ',
  'օրերէ',
  'թանագ',
  'կախել',
  'տունէ',
  'հանեց',
  'մանկօ',
  'քաղոց',
  'նոթեր',
  'ոսկոր',
  'հինայ',
  'նշեալ',
  'ասացի',
  'թեւին',
  'կրիոն',
  'փխրել',
  'ջրիկն',
  'ապսիդ',
  'բոյսը',
  'ձիրքն',
  'ծնգոց',
  'քարաղ',
  'արծիւ',
  'շշմոլ',
  'խրուտ',
  'խոցոտ',
  'սիլլա',
  'ոգիով',
  'լիսեռ',
  'տնքալ',
  'վերեւ',
  'թաւշէ',
  'թերին',
  'դեղեր',
  'րարքը',
  'լիզել',
  'պլշել',
  'շոշոտ',
  'մեղայ',
  'հնոցն',
  'կրեմը',
  'հավով',
  'սալսա',
  'սկիւթ',
  'կախեն',
  'դալար',
  'մահեն',
  'ջուխտ',
  'եզակն',
  'կռվող',
  'կազմի',
  'խմորէ',
  'սիբան',
  'ամեհի',
  'տոչոր',
  'կծկիլ',
  'տարեց',
  'ըլլայ',
  'սպառէ',
  'քավեր',
  'էնդիի',
  'լաւաշ',
  'չալիկ',
  'քջջել',
  'մաջառ',
  'վերջն',
  'տռճիկ',
  'շամամ',
  'օտինը',
  'տոտալ',
  'խրոխտ',
  'դպչիլ',
  'խոցիչ',
  'խճեայ',
  'գռում',
  'կորեկ',
  'արքան',
  'թրեքի',
  'ատլաս',
  'միտքը',
  'տնկած',
  'շուիկ',
  'մանրէ',
  'սրահն',
  'անտէր',
  'նեղել',
  'օինակ',
  'խածաւ',
  'գարին',
  'ցինկի',
  'տեղին',
  'փեկոն',
  'ավագը',
  'անյօդ',
  'խաղող',
  'չդնել',
  'ժպտար',
  'եվայի',
  'պլուզ',
  'կոխել',
  'պատել',
  'դմփիկ',
  'վշշալ',
  'դատել',
  'պտղին',
  'նետեն',
  'եռքոր',
  'կքում',
  'մզում',
  'ջրտու',
  'առածի',
  'եղիցի',
  'անգէտ',
  'խշուր',
  'կերար',
  'առկան',
  'կրցաւ',
  'բացիք',
  'ռիթմի',
  'գօտիկ',
  'ուսեղ',
  'հոգոց',
  'նամակ',
  'կրօնի',
  'ֆէսին',
  'շրշիլ',
  'հսկած',
  'վարգի',
  'մնայէ',
  'ֆրանց',
  'խաթեր',
  'ծովից',
  'սկիւռ',
  'անմիտ',
  'լղճել',
  'թոքեր',
  'խշտիկ',
  'կեանք',
  'պոռոչ',
  'սիրոն',
  'անհոգ',
  'մկանի',
  'չարիք',
  'մոլար',
  'խորոմ',
  'արկած',
  'պարոք',
  'ոստան',
  'խէժոտ',
  'բիլսը',
  'աստղը',
  'գերբն',
  'փշտով',
  'պառակ',
  'մտքին',
  'զեման',
  'վիշապ',
  'վճարը',
  'հաւար',
  'կողմը',
  'լիոնը',
  'սթեդի',
  'հնաոճ',
  'սկիհը',
  'անխոհ',
  'չխկոց',
  'կրիփը',
  'այրեր',
  'մտքէն',
  'տարրն',
  'կընէր',
  'հիմեն',
  'թափօն',
  'շոգել',
  'նիստը',
  'փուխր',
  'ըսինք',
  'թելիկ',
  'ուստր',
  'անքէն',
  'քոթոթ',
  'կլանի',
  'անկաթ',
  'ծովիկ',
  'նստաք',
  'բոբոխ',
  'պտտել',
  'չորել',
  'հիմնը',
  'խճճել',
  'թենիս',
  'բիրնի',
  'սփիւռ',
  'կծում',
  'կողքի',
  'դերէն',
  'սյանը',
  'խոցել',
  'հոտեր',
  'ահւալ',
  'նիզակ',
  'դիւան',
  'խորան',
  'դերով',
  'ընտայ',
  'պատիժ',
  'ադչու',
  'քլեյը',
  'գոցեն',
  'փլվել',
  'կցում',
  'գորգի',
  'ցրցամ',
  'ղժժան',
  'յաճախ',
  'մութը',
  'բռնեն',
  'պահիկ',
  'յանգի',
  'կլանչ',
  'փուլը',
  'դիմեն',
  'նշման',
  'տրդատ',
  'գետառ',
  'ձիուն',
  'մշակէ',
  'ծամոց',
  'արփին',
  'տունն',
  'անհէր',
  'դզում',
  'բերդի',
  'ցանքի',
  'իվանը',
  'պղերգ',
  'վարող',
  'ոմանք',
  'յղկիչ',
  'կարել',
  'պտղիլ',
  'իմամը',
  'ամառը',
  'գներն',
  'զենքն',
  'դզզոց',
  'ատում',
  'մորթե',
  'գրելը',
  'ցանկը',
  'կոմիկ',
  'հոլակ',
  'դերին',
  'միացք',
  'անդէմ',
  'ուշով',
  'ծեծով',
  'ճեմել',
  'դագաղ',
  'գիշեր',
  'ձգտել',
  'լինեն',
  'տկճոր',
  'հարավ',
  'կոտոշ',
  'անաղի',
  'դարից',
  'երշիկ',
  'ձագար',
  'հեծան',
  'իրարո',
  'ապասը',
  'տէմին',
  'եզնիկ',
  'շրդան',
  'ոտքեր',
  'ղրկել',
  'օդերի',
  'կտրօն',
  'քրոջս',
  'ոտնակ',
  'հորից',
  'փրչոտ',
  'կաշվե',
  'թմբիչ',
  'խնչել',
  'թուեր',
  'սնուէ',
  'զգանք',
  'խոսքի',
  'տմբալ',
  'բասրա',
  'ձեւին',
  'բադամ',
  'վշշոց',
  'մորից',
  'մօրմէ',
  'ծայրը',
  'խորքը',
  'խոտել',
  'լուծի',
  'գանկը',
  'դրժել',
  'ծնկել',
  'թուրծ',
  'այգու',
  'տենդի',
  'ջրվեժ',
  'պատից',
  'սեքսի',
  'ըրինք',
  'ցամաք',
  'յածիլ',
  'բրնձը',
  'սկսան',
  'բոքոն',
  'անծին',
  'երազի',
  'անթեմ',
  'կնձնի',
  'անում',
  'ֆրակի',
  'պճճալ',
  'թողիք',
  'զատիկ',
  'բերիր',
  'ջղախտ',
  'խոկալ',
  'գրաւէ',
  'մազոտ',
  'մահին',
  'միտիլ',
  'ձեւեր',
  'նետիկ',
  'կոպար',
  'կիկան',
  'բոռոց',
  'կապրի',
  'դարան',
  'մանել',
  'էպոսի',
  'բուոց',
  'դեղոտ',
  'տառին',
  'լցվել',
  'չոռոտ',
  'ձևերի',
  'գլդոն',
  'լեղակ',
  'յառած',
  'տեւել',
  'ծնկին',
  'նեյթը',
  'հակին',
  'փուստ',
  'սիգալ',
  'սղղալ',
  'թիթեռ',
  'կովեր',
  'կաթից',
  'թուրն',
  'կաբել',
  'իկթան',
  'գավթի',
  'ալիսա',
  'միջից',
  'դիւոտ',
  'սիրով',
  'տաթևի',
  'սիրող',
  'փքալի',
  'գրպան',
  'խափան',
  'ցեւով',
  'թղթիկ',
  'ծիծաղ',
  'ազգեր',
  'լսենք',
  'ծռանց',
  'սառատ',
  'կական',
  'թզուկ',
  'նետել',
  'անմաս',
  'իպսեն',
  'խուրձ',
  'քիմոն',
  'ծառան',
  'տեռոր',
  'անամպ',
  'անքաշ',
  'մեթոտ',
  'ջրալի',
  'լազաթ',
  'թիմեր',
  'սալար',
  'խնթոց',
  'նաֆաս',
  'պասիվ',
  'հենել',
  'պիտակ',
  'խելքը',
  'վիժել',
  'թուզը',
  'կտաւի',
  'ամենը',
  'կոդեր',
  'կարաս',
  'ձգտիլ',
  'մէծէն',
  'այգով',
  'ճաշէն',
  'սովոր',
  'ալանի',
  'ժորժն',
  'աւլել',
  'կոկիկ',
  'գութը',
  'պաղիլ',
  'մաշիլ',
  'ասպար',
  'լանջք',
  'նաւով',
  'դխկոց',
  'ուտիք',
  'պետիի',
  'գրչակ',
  'սոկաս',
  'անհետ',
  'օտեան',
  'դռռան',
  'պպզիլ',
  'կցորդ',
  'գրոսը',
  'ճաղատ',
  'գիտէք',
  'կիզիչ',
  'ճռռալ',
  'խելառ',
  'շինեմ',
  'կուլտ',
  'ցցում',
  'մեթրն',
  'զինքը',
  'երթայ',
  'գային',
  'անկար',
  'քշպել',
  'սարքի',
  'օձերի',
  'կաքաւ',
  'ծեփան',
  'բազար',
  'չռռալ',
  'կոկիչ',
  'ելման',
  'աղինջ',
  'եռանդ',
  'վշտից',
  'հակած',
  'հողել',
  'գանգի',
  'սոուս',
  'ապրեր',
  'տքնիլ',
  'քծնող',
  'շրջան',
  'վարազ',
  'մաղթէ',
  'նշվել',
  'շմփոց',
  'վարել',
  'ավարտ',
  'ջերմէ',
  'տինքի',
  'տուաւ',
  'ջրհան',
  'թսկել',
  'մացառ',
  'շառոտ',
  'սինգլ',
  'անճառ',
  'ետեւի',
  'ասիկա',
  'կուշտ',
  'թնգոց',
  'կաղնի',
  'փառքը',
  'դեւոտ',
  'նալիկ',
  'ծեսեր',
  'դմբալ',
  'կուրտ',
  'ղոնաղ',
  'աղեղը',
  'սիրէր',
  'անջիլ',
  'մկնոռ',
  'մունջ',
  'զամշէ',
  'ուլիկ',
  'պրծան',
  'երգով',
  'աջում',
  'ջուրէ',
  'հինել',
  'դիպել',
  'ծալել',
  'լուրթ',
  'զգեստ',
  'լծորդ',
  'տալով',
  'սրբան',
  'գազան',
  'տրաստ',
  'գոճան',
  'սթեդը',
  'տուֆէ',
  'կլինի',
  'գրերը',
  'հիդրո',
  'տաշած',
  'բրեան',
  'պրևոն',
  'նայող',
  'կռնատ',
  'գույն',
  'ճոպան',
  'ոտքել',
  'հյուն',
  'թաթեր',
  'գիրքն',
  'դնում',
  'կրողը',
  'բերաւ',
  'բախիլ',
  'շղթայ',
  'լայիր',
  'տիրել',
  'շոգին',
  'գծերը',
  'դաբաղ',
  'նորից',
  'էրոցք',
  'բառաչ',
  'բարեն',
  'թուրմ',
  'էրկան',
  'տարափ',
  'կթում',
  'կոծիկ',
  'խոխոմ',
  'թովել',
  'հեզար',
  'երազն',
  'ֆրիզի',
  'դխկալ',
  'տայիր',
  'դաջել',
  'անուժ',
  'իրինց',
  'շիւար',
  'մսուր',
  'վերջի',
  'տածել',
  'կապոտ',
  'ելակը',
  'հերոս',
  'ըմպակ',
  'օսեյջ',
  'տարրի',
  'թնդոց',
  'եզրից',
  'ֆոնտը',
  'շշիւն',
  'անմեռ',
  'խտուտ',
  'կերտէ',
  'գալիք',
  'փոքրն',
  'համաս',
  'հուսկ',
  'տակել',
  'գայիր',
  'միզել',
  'տափել',
  'ձմրան',
  'սևերի',
  'ջրթող',
  'տաճար',
  'հազար',
  'փայակ',
  'ցնորք',
  'վանայ',
  'այրին',
  'բնդել',
  'հրատը',
  'ճնկալ',
  'թուալ',
  'քուչի',
  'գաւին',
  'հռեայ',
  'անզէն',
  'մկկոց',
  'էփսին',
  'թմրած',
  'տվյալ',
  'դարձի',
  'ջարդը',
  'դառան',
  'հոգու',
  'մտգով',
  'թաւալ',
  'տողեր',
  'ճռզած',
  'միջին',
  'մատիկ',
  'սիպիլ',
  'ժխտել',
  'անղեկ',
  'շարել',
  'տայիք',
  'փայիկ',
  'մերլի',
  'շողոտ',
  'վարար',
  'չարայ',
  'կրիայ',
  'ճմուտ',
  'կտորը',
  'չեչիլ',
  'կապեր',
  'հրարկ',
  'թրըխկ',
  'յառել',
  'քայլի',
  'մարզէ',
  'նուոց',
  'թեթեւ',
  'գրաֆը',
  'լային',
  'մանգա',
  'բուտի',
  'պլանը',
  'թռնել',
  'միսին',
  'օղակի',
  'գլխել',
  'տարեխ',
  'էրինջ',
  'հեղեղ',
  'տուփը',
  'հրատի',
  'նրանց',
  'անփող',
  'կարլը',
  'գայլը',
  'պլոկը',
  'զարըգ',
  'թրծել',
  'աւրել',
  'անբոց',
  'անհաց',
  'երկնք',
  'անտակ',
  'զինել',
  'վարեց',
  'թառամ',
  'մինակ',
  'կեսէն',
  'ագցան',
  'տնկել',
  'ծովէն',
  'պատած',
  'ամօթի',
  'յեսան',
  'զառամ',
  'աչքին',
  'վզնոց',
  'ըլլան',
  'հրում',
  'ֆունտ',
  'թերթը',
  'ամրակ',
  'խռփալ',
  'փոփոլ',
  'վայել',
  'քաոսի',
  'բժշկի',
  'որոշէ',
  'թնգալ',
  'բացեն',
  'հաշվի',
  'կողակ',
  'անդեղ',
  'տնքոց',
  'զույգ',
  'բօթել',
  'երկալ',
  'օղաձև',
  'փխլել',
  'մոդել',
  'նազիկ',
  'նստէր',
  'զտում',
  'շաչել',
  'ռեգգի',
  'ճաշոց',
  'գնացի',
  'գրուի',
  'երգից',
  'ագռաւ',
  'նժդեհ',
  'շափել',
  'դիմել',
  'խարամ',
  'անթոց',
  'հասցէ',
  'սգերգ',
  'երկրէ',
  'քշէին',
  'դոլար',
  'կոլոն',
  'կամեո',
  'շեղաչ',
  'դռնէն',
  'մեռոն',
  'զոնայ',
  'կոթող',
  'բեկել',
  'որմել',
  'չարվի',
  'բորոր',
  'արամէ',
  'սիրոյ',
  'պարով',
  'վանեն',
  'շոփէն',
  'պարին',
  'պղպեղ',
  'ձմեռը',
  'գրածը',
  'փրկեն',
  'կապայ',
  'ծամիկ',
  'որեւէ',
  'գեհեն',
  'պտկիկ',
  'գտանք',
  'լցուկ',
  'շտրիխ',
  'վիգէն',
  'սակառ',
  'ճոռոմ',
  'տարազ',
  'լողաթ',
  'ղալիմ',
  'խալոտ',
  'այծեր',
  'փսարա',
  'հարու',
  'եղուկ',
  'կառան',
  'հնձել',
  'դաւան',
  'վիճակ',
  'ձգտին',
  'այրիչ',
  'ապրիլ',
  'անմահ',
  'ափերը',
  'շփփալ',
  'սուրճ',
  'ուտես',
  'պարած',
  'քարեր',
  'անուն',
  'բզզալ',
  'ինկայ',
  'օգնել',
  'որձատ',
  'հասավ',
  'անկան',
  'ադամը',
  'ազդու',
  'խնդրի',
  'ճռինչ',
  'առջևի',
  'դուրճ',
  'բռնակ',
  'տիֆով',
  'կամայ',
  'ցուլը',
  'դժմիտ',
  'շէնքէ',
  'ծոցափ',
  'ռելիէ',
  'քայլն',
  'հոգին',
  'ճռթոց',
  'իխտիս',
  'գլխատ',
  'առթիւ',
  'սրքան',
  'ձիւնը',
  'բերնէ',
  'կրակէ',
  'մեջքը',
  'հաւաք',
  'ճշդել',
  'ծովակ',
  'քանին',
  'ծմրոց',
  'անջիղ',
  'շիլան',
  'թացան',
  'բարեի',
  'որդոտ',
  'պարզէ',
  'տորան',
  'որսին',
  'ակորդ',
  'ցածիկ',
  'ձուիկ',
  'իշխող',
  'շաքիլ',
  'ապազը',
  'վարքի',
  'ցանցն',
  'ճռռոց',
  'փապար',
  'ջրփախ',
  'կպրիչ',
  'որքին',
  'միաձի',
  'աճում',
  'կաշվի',
  'չոմախ',
  'կերան',
  'հոսիլ',
  'կային',
  'գղթոր',
  'նաֆտի',
  'պահոց',
  'վեգան',
  'լուրջ',
  'թովիչ',
  'հեկթօ',
  'ծափիկ',
  'լինին',
  'ղժժալ',
  'բալետ',
  'անհատ',
  'հրեշի',
  'տպուի',
  'մաշկը',
  'թուէր',
  'մտնող',
  'ապուշ',
  'էթելը',
  'կակազ',
  'պահեն',
  'քլթոց',
  'նավթի',
  'մաներ',
  'հիացք',
  'ուրագ',
  'հինդի',
  'գերբի',
  'թխպել',
  'թավոտ',
  'ստորը',
  'ձեւել',
  'ասկից',
  'օփերա',
  'ղասաբ',
  'ոլոռն',
  'ատամը',
  'սալթա',
  'սառցե',
  'ջուրն',
  'պտտին',
  'կարօտ',
  'սնում',
  'սերմը',
  'ջուտն',
  'չախալ',
  'պեղել',
  'լատիշ',
  'փոխել',
  'ալիւր',
  'ագահը',
  'անութ',
  'պեկից',
  'երանի',
  'խոռոչ',
  'դնովի',
  'փռուի',
  'բալայ',
  'անշառ',
  'սխալը',
  'չմփալ',
  'հորոր',
  'գտնեն',
  'ժպիրհ',
  'էտքան',
  'աղման',
  'փուքս',
  'պանան',
  'կողեր',
  'ախյալ',
  'ծծկեր',
  'բաժին',
  'նզովք',
  'կապալ',
  'եղծիչ',
  'լալիկ',
  'հիմքն',
  'անթեղ',
  'կսկսի',
  'խօջայ',
  'անքակ',
  'կապոց',
  'թպպոց',
  'քուրձ',
  'օգնող',
  'բարակ',
  'անշէջ',
  'նազիլ',
  'կորքի',
  'խօսող',
  'չգիտի',
  'մեկին',
  'խրճիթ',
  'ներկը',
  'ծամիչ',
  'կոպիճ',
  'իւղիչ',
  'ջրոսպ',
  'ժիւրի',
  'չիբան',
  'խզում',
  'իտէալ',
  'անհոտ',
  'ամենէ',
  'խարազ',
  'ուտիճ',
  'հացով',
  'խէթել',
  'հունտ',
  'դնգալ',
  'ձգտէր',
  'ջեռոց',
  'գործն',
  'աճման',
  'տուին',
  'աւերը',
  'լիդեր',
  'տուֆի',
  'հումք',
  'մուկը',
  'փոշոտ',
  'խժռել',
  'փոթոթ',
  'խիժոտ',
  'մարիլ',
  'ձողիկ',
  'լայնք',
  'զարկի',
  'ընկան',
  'փռված',
  'տարաւ',
  'քննիչ',
  'վրայի',
  'արծաթ',
  'սեպել',
  'մարտի',
  'ագուռ',
  'անսէր',
  'կանտի',
  'ճնշիչ',
  'գաջոտ',
  'տհոլի',
  'բդեշխ',
  'խմում',
  'կռռոց',
  'տուած',
  'դրեցի',
  'կատար',
  'զուսպ',
  'փլուզ',
  'դալալ',
  'թուրք',
  'խօսիր',
  'տների',
  'գնացք',
  'առնել',
  'ճուոց',
  'ուղիղ',
  'վագրի',
  'ծմրիլ',
  'փորիկ',
  'հոգիի',
  'միոզի',
  'մնալէ',
  'փախիլ',
  'կնոջն',
  'դպրոց',
  'վաշտի',
  'ծաղրն',
  'գամեց',
  'ներքո',
  'փտման',
  'բժիշկ',
  'աղեղի',
  'անուր',
  'տարիֆ',
  'կրծել',
  'փրկիչ',
  'թխկին',
  'կադետ',
  'փեթակ',
  'կայքը',
  'տեսաւ',
  'տաշել',
  'տաճիկ',
  'թփուտ',
  'կինայ',
  'թաւոտ',
  'յարկը',
  'գազոն',
  'պահէր',
  'ելլեն',
  'սկսաւ',
  'ոնրիշ',
  'ձիթել',
  'ճգնիլ',
  'քունն',
  'տիկին',
  'կարան',
  'չափից',
  'ժպտիլ',
  'գրաստ',
  'ձիերի',
  'դարձը',
  'բարոն',
  'հերիք',
  'սէզոտ',
  'զօրեղ',
  'արանք',
  'կրտել',
  'ուզող',
  'ձգվել',
  'բոված',
  'անփառ',
  'կպրոտ',
  'եռակի',
  'թամամ',
  'գոլին',
  'շառաչ',
  'զօրել',
  'լուալ',
  'ներքը',
  'փրենի',
  'հարին',
  'շալէր',
  'քերոց',
  'ցաւօք',
  'թոնիր',
  'հազիվ',
  'գխտոր',
  'ձգում',
  'կոմսը',
  'սեռով',
  'նիքիի',
  'թնդիլ',
  'գաւառ',
  'երեքը',
  'հացին',
  'սղղան',
  'սովէն',
  'լույս',
  'բազազ',
  'շողիկ',
  'ստոյգ',
  'աչում',
  'բախտի',
  'սարած',
  'կրանյ',
  'մշեցի',
  'կեցցէ',
  'ջոնսը',
  'խոնավ',
  'գանիլ',
  'օգիեն',
  'սոֆին',
  'որդան',
  'խոտոր',
  'չմտած',
  'դշխեմ',
  'պինայ',
  'պճնել',
  'ոսկրէ',
  'նաֆթի',
  'պետեր',
  'թրջել',
  'դօդօշ',
  'բռինչ',
  'սօսափ',
  'նստած',
  'մեջքն',
  'բուրդ',
  'դաշտը',
  'թրմոց',
  'սերով',
  'երթին',
  'եզերք',
  'ծլինգ',
  'ննջող',
  'ազատը',
  'առնոն',
  'վիպակ',
  'անտիպ',
  'ելքին',
  'երանգ',
  'լխտիկ',
  'եուրո',
  'վիճող',
  'ցրված',
  'եթերք',
  'գնում',
  'լաւիտ',
  'կելլէ',
  'բանտը',
  'դժգոհ',
  'մերին',
  'կռիւի',
  'դառած',
  'ծորիլ',
  'բարոք',
  'բախած',
  'հատաւ',
  'մզկիթ',
  'կերաք',
  'զօրքի',
  'կաթով',
  'փորին',
  'ցուլի',
  'լրտես',
  'դիմող',
  'զմփալ',
  'բանայ',
  'մայիս',
  'կայան',
  'վառօդ',
  'կայտի',
  'ընչեղ',
  'ջորջը',
  'լատին',
  'հեղել',
  'վասիլ',
  'վիզայ',
  'արջեր',
  'սեանը',
  'արճիճ',
  'սակաւ',
  'արենի',
  'տխրիլ',
  'կարապ',
  'շահիլ',
  'վէպին',
  'որչափ',
  'ձթենի',
  'օժտել',
  'տժժոց',
  'որբեր',
  'ցրվել',
  'տուիր',
  'ստինք',
  'լուիճ',
  'մուրճ',
  'քնելը',
  'մեռաւ',
  'բաւել',
  'խաւիծ',
  'թափիչ',
  'խռկոց',
  'բացին',
  'լծակը',
  'իստակ',
  'պոպոք',
  'վիրոտ',
  'կեռաս',
  'տրեբլ',
  'շատեր',
  'մարզը',
  'ցնցող',
  'կրքոտ',
  'գրեգը',
  'գոռոց',
  'ջեյնը',
  'շունչ',
  'մաքոք',
  'ծնեալ',
  'պոմպի',
  'աշոտի',
  'բերդը',
  'ձգուի',
  'նեղոս',
  'ամպոտ',
  'սուան',
  'վնգոց',
  'բաբախ',
  'լաստի',
  'ճարպի',
  'սմքին',
  'կտտիլ',
  'ծոցին',
  'ճղփալ',
  'մարագ',
  'գրոհը',
  'խորքի',
  'անկապ',
  'դեմքի',
  'ոլորք',
  'ճանար',
  'շահած',
  'մեծաւ',
  'խոհիլ',
  'լողով',
  'տեսիլ',
  'թանին',
  'յենու',
  'խստիւ',
  'յաւէտ',
  'փուլի',
  'հասնի',
  'ոչխար',
  'աստծո',
  'նուագ',
  'բաքոս',
  'սամիթ',
  'կայիք',
  'ցաքատ',
  'շռռալ',
  'թաղար',
  'վերան',
  'բոսխի',
  'ծփում',
  'ռիսկի',
  'կրթիչ',
  'գրելն',
  'ձևերը',
  'ծէգել',
  'ապշիլ',
  'յօդել',
  'կշռար',
  'կազակ',
  'պամիա',
  'յենոց',
  'գհուկ',
  'խռնել',
  'սովել',
  'նուէր',
  'մզրախ',
  'տեղով',
  'ծեփել',
  'ումով',
  'վրձին',
  'չպետք',
  'քամայ',
  'զօծել',
  'տօնել',
  'կուղբ',
  'գողօն',
  'էգանը',
  'մետրօ',
  'անխաբ',
  'խռփոց',
  'խրթել',
  'զտման',
  'խաղալ',
  'վռթալ',
  'ձուկը',
  'յօժար',
  'հսկեն',
  'սնվել',
  'ընտրէ',
  'վնասի',
  'պոլիս',
  'գեննա',
  'շինած',
  'տենդը',
  'ստելը',
  'վթարի',
  'մերել',
  'դրինք',
  'միտքի',
  'բորոտ',
  'չլսեց',
  'զմփոց',
  'տժժալ',
  'թխլիկ',
  'ծիծառ',
  'ամեակ',
  'գողտր',
  'կտավն',
  'բերող',
  'սրբիչ',
  'նիւթը',
  'բզզան',
  'պահքը',
  'ծեծէր',
  'ամռան',
  'լճեզր',
  'շառատ',
  'ետեւէ',
  'օսկոր',
  'ձգտած',
  'կրէին',
  'թռանք',
  'դուրը',
  'մրցին',
  'աւիշի',
  'դպչին',
  'թեւատ',
  'տարել',
  'չխկալ',
  'ժամեր',
  'աշխօր',
  'խմբին',
  'սոդայ',
  'ակտեր',
  'կերգէ',
  'տամար',
  'դիմակ',
  'կոպիտ',
  'խլինք',
  'արածն',
  'փոյին',
  'թմփալ',
  'դասին',
  'կոճակ',
  'մազեր',
  'նշում',
  'լերան',
  'խլնոտ',
  'ճխլել',
  'չխմեց',
  'ռասայ',
  'կազեր',
  'հարոց',
  'անդին',
  'փքախտ',
  'տիտան',
  'կապիկ',
  'գոռալ',
  'ֆրենչ',
  'հակիլ',
  'փակել',
  'դաժան',
  'տեւած',
  'խխմիլ',
  'խիլոտ',
  'տեղից',
  'պէմօլ',
  'թուղթ',
  'ճիգին',
  'պահքի',
  'պսակը',
  'կույր',
  'բուհը',
  'ակասի',
  'քրդեր',
  'ճաշից',
  'խաբար',
  'նոտայ',
  'հալիլ',
  'պաթոս',
  'թողին',
  'մոմել',
  'գոյին',
  'մեխել',
  'սմբատ',
  'բանով',
  'շնչեղ',
  'կակաչ',
  'նեխիլ',
  'գիհին',
  'տերևն',
  'նազիր',
  'լաւիկ',
  'ռոմեո',
  'խզզալ',
  'շոյիչ',
  'գյուղ',
  'թորակ',
  'գցեցի',
  'հազոց',
  'ուրան',
  'կապեն',
  'ասուպ',
  'իոսիֆ',
  'հոտոտ',
  'ալպոմ',
  'անձեր',
  'շլմոր',
  'թէյին',
  'ցավից',
  'ըսուի',
  'բերեն',
  'աղուն',
  'ծծմոր',
  'ներել',
  'շահէն',
  'դակել',
  'պլզել',
  'ընտրի',
  'ցրում',
  'դողոց',
  'աչքեր',
  'քծնիլ',
  'բախին',
  'չքնաղ',
  'բայոց',
  'չրթել',
  'պարկի',
  'շռայլ',
  'ճեպել',
  'հոտին',
  'որկոր',
  'ծագած',
  'տանել',
  'հիւկօ',
  'խօսքի',
  'տաղեր',
  'սկսկի',
  'ջրոգի',
  'քարով',
  'ռադիօ',
  'դրւած',
  'ուտել',
  'ցնցիչ',
  'քուրմ',
  'տանքա',
  'կնձիկ',
  'զորքը',
  'հէրին',
  'այդկէ',
  'սրանց',
  'բղխիլ',
  'ծլթալ',
  'գովքն',
  'շիներ',
  'դողիլ',
  'հօտին',
  'մահէն',
  'հինէն',
  'թևիսի',
  'տարրը',
  'ամէնը',
  'մտքով',
  'ղալամ',
  'խոզակ',
  'ձախէն',
  'թափել',
  'դժժալ',
  'փրթիլ',
  'պատեր',
  'ճնշող',
  'շրջած',
  'ջրդար',
  'բումը',
  'ծռերը',
  'վիզին',
  'պնդել',
  'ճօճիլ',
  'թափով',
  'բանից',
  'շրջել',
  'քաչալ',
  'ցցւիլ',
  'բռնել',
  'չոդառ',
  'կազին',
  'էթիկա',
  'եղէգն',
  'թխկալ',
  'թանից',
  'ցօղիլ',
  'տեղմը',
  'խրում',
  'ռիատի',
  'կախան',
  'կնքած',
  'բումբ',
  'զոհեր',
  'ակամա',
  'ծկթել',
  'ձնձեղ',
  'ծեփիչ',
  'պանրի',
  'ակաևի',
  'շոուի',
  'փախչի',
  'թրջիլ',
  'մորթի',
  'տարիէ',
  'շրջիկ',
  'ճմլիչ',
  'ռիթմը',
  'առնող',
  'հնում',
  'ճխտել',
  'շագին',
  'նռենի',
  'տապար',
  'փստալ',
  'քամել',
  'աւանդ',
  'հոբոյ',
  'օսկար',
  'ռիոյի',
  'դամար',
  'կուպէ',
  'խռկալ',
  'վտակն',
  'ինձմէ',
  'դաշտի',
  'եղերդ',
  'եաբու',
  'շմփալ',
  'պատան',
  'ոհմակ',
  'հնչած',
  'կոթառ',
  'ձորափ',
  'անպոզ',
  'նիկել',
  'բլրակ',
  'մտքեր',
  'թխպոտ',
  'դրուի',
  'ձիուց',
  'էոլիթ',
  'մլուլ',
  'թիմոլ',
  'տատից',
  'կտաւէ',
  'սգերթ',
  'ցաւոտ',
  'չաթու',
  'անդրի',
  'մատիտ',
  'պակեթ',
  'խալաթ',
  'արտել',
  'մլեհը',
  'տարաք',
  'պնչատ',
  'տեղիք',
  'աննան',
  'բիցցա',
  'սկսայ',
  'շիրոտ',
  'կնիւն',
  'թէպէտ',
  'քսում',
  'ներկա',
  'տեւեր',
  'եչկար',
  'խմբել',
  'լևոնի',
  'քերին',
  'թխում',
  'էտում',
  'ծլկիլ',
  'անչափ',
  'ճնշէր',
  'ձնհալ',
  'շարժի',
  'պիտոյ',
  'ըմպել',
  'ունիմ',
  'տոկալ',
  'ցնցած',
  'գրողը',
  'երկձի',
  'գուցէ',
  'որակը',
  'շուկա',
  'բոժոժ',
  'կարծէ',
  'կողին',
  'վճիռը',
  'ամբար',
  'իրենն',
  'սիսեռ',
  'հիւսք',
  'ուծին',
  'պղծիչ',
  'ծածկն',
  'կոփել',
  'թասակ',
  'օտենի',
  'լցում',
  'քաղցր',
  'ձգտող',
  'դուրգ',
  'լացին',
  'կապից',
  'գրեմի',
  'մոտիվ',
  'վերևի',
  'ֆալակ',
  'ջանիկ',
  'գտնէր',
  'ծլլալ',
  'կղղալ',
  'խաղար',
  'ֆոնդի',
  'ամանի',
  'կտրիչ',
  'օղակը',
  'մատնէ',
  'կրծեն',
  'սարիկ',
  'ատելի',
  'դաղել',
  'մինոր',
  'ահեակ',
  'ալիբի',
  'պոչատ',
  'սիրտը',
  'վաֆլե',
  'հարաւ',
  'բացատ',
  'լքված',
  'անվան',
  'էդպէս',
  'կնորս',
  'պոռալ',
  'ջանար',
  'թալան',
  'օծման',
  'պղծել',
  'խեղել',
  'թթախտ',
  'տաղիկ',
  'անարի',
  'սեղմէ',
  'ազդել',
  'լողակ',
  'զաւակ',
  'թփերի',
  'առիթն',
  'արժեք',
  'խոզեր',
  'ցատկէ',
  'անբեր',
  'վազեր',
  'կճղակ',
  'անդատ',
  'վզզալ',
  'անլաց',
  'զատել',
  'հովոց',
  'վշշան',
  'կապած',
  'պոռոց',
  'յաճակ',
  'կալոց',
  'չափեց',
  'եփվող',
  'ելոյթ',
  'կշեռք',
  'գլխոց',
  'լսեցի',
  'վաշտը',
  'փէշակ',
  'դուռը',
  'ջրերն',
  'կրճոն',
  'ռոճիկ',
  'դրախտ',
  'ողորմ',
  'արյան',
  'լխպոր',
  'ոլորտ',
  'տզզան',
  'գլխիկ',
  'կոչեն',
  'կաւոտ',
  'ռոուզ',
  'զեւսը',
  'մռռալ',
  'դրինդ',
  'ճղճիմ',
  'օգներ',
  'կենալ',
  'թզենի',
  'տապակ',
  'անծիլ',
  'քոչել',
  'սունկ',
  'բնորդ',
  'դարակ',
  'ձգէին',
  'դեռես',
  'չրըխկ',
  'տմբոց',
  'ունէի',
  'պահէն',
  'լոսել',
  'ներկի',
  'քնախտ',
  'ակութ',
  'լամայ',
  'բունը',
  'գերաճ',
  'փաստն',
  'աճուն',
  'չբանի',
  'ովքեր',
  'նըւէօ',
  'նիւթն',
  'գչրել',
  'բժիղկ',
  'ձորան',
  'էթնիք',
  'վառիչ',
  'ումից',
  'հովիտ',
  'ջհուտ',
  'կիսրդ',
  'փուռը',
  'ճստիկ',
  'մորիս',
  'ձեռքի',
  'օմանը',
  'թաթառ',
  'բշտել',
  'էսքիզ',
  'ունիս',
  'նամազ',
  'ձօնել',
  'կոզռն',
  'պսակի',
  'լայտը',
  'չինթա',
  'նական',
  'գլխով',
  'տմարդ',
  'կլիմա',
  'կիսատ',
  'խնկոտ',
  'խոժոռ',
  'չմնաց',
  'ձեռքն',
  'իշիաս',
  'այլոք',
  'գահից',
  'հովիւ',
  'ամառն',
  'սանդի',
  'լիսէի',
  'ձողան',
  'ոտկապ',
  'կաթոց',
  'սերեր',
  'լացող',
  'չպէտք',
  'կգնամ',
  'հաշիշ',
  'քելեխ',
  'պարոն',
  'խխում',
  'կրկին',
  'եղեռն',
  'անքաղ',
  'բացած',
  'ռատիօ',
  'տեսակ',
  'վտանք',
  'ժշխել',
  'դոնոր',
  'լիներ',
  'երեկո',
  'երեքն',
  'տակին',
  'նախշը',
  'մազին',
  'հավեր',
  'կապան',
  'աբոնը',
  'լաքել',
  'անվախ',
  'զրկեց',
  'գոռոզ',
  'սատար',
  'զգալի',
  'հաճոյ',
  'լեգալ',
  'սոնիճ',
  'մոխրի',
  'ճյուղ',
  'ֆիդես',
  'կցուկ',
  'մաժոր',
  'հարամ',
  'անգոհ',
  'քունը',
  'աղուր',
  'վեպեր',
  'թուով',
  'դադրի',
  'եղբոր',
  'իրերը',
  'անծոր',
  'խճճոց',
  'խիզախ',
  'ոստիկ',
  'կապին',
  'կեցած',
  'ալբան',
  'բրոնզ',
  'կրակի',
  'թազբէ',
  'իներտ',
  'լինող',
  'չորոց',
  'առլէզ',
  'ցանցի',
  'հրդեհ',
  'խոշոր',
  'հայու',
  'գուցե',
  'հարցը',
  'տածեր',
  'տարած',
  'հարեմ',
  'մախաթ',
  'փքանք',
  'ազնիվ',
  'տայգա',
  'էական',
  'աուլը',
  'շիտակ',
  'քարտի',
  'սկսող',
  'քղանց',
  'հնդիկ',
  'կաշու',
  'իւղին',
  'մարքի',
  'տենչը',
  'կնոջը',
  'ծախսը',
  'թոքին',
  'պիրսն',
  'մատով',
  'հետին',
  'նշանը',
  'օրբիտ',
  'ելլող',
  'անզօր',
  'լսէին',
  'բիւջէ',
  'խնամի',
  'չէզոք',
  'ծպտիլ',
  'հնչակ',
  'ռեփին',
  'ռայսի',
  'ծաւալ',
  'դեղին',
  'տխմար',
  'կամար',
  'հատով',
  'մէջէն',
  'հենէր',
  'սանտր',
  'բծերի',
  'խումբ',
  'ծղծամ',
  'բագին',
  'պինար',
  'հռչակ',
  'բազան',
  'երեսի',
  'խշրել',
  'պրծար',
  'գլասը',
  'շողոմ',
  'արոյր',
  'սեքսը',
  'հայրն',
  'մաժել',
  'հեւալ',
  'թխված',
  'շանյի',
  'հացէն',
  'մասիա',
  'հոսքը',
  'ցեխել',
  'գիսակ',
  'շնիկի',
  'անպոչ',
  'երևալ',
  'տզզոց',
  'մնալը',
  'դրոշի',
  'կթռչի',
  'անբան',
  'բալիշ',
  'շտաբի',
  'մղլակ',
  'թափօր',
  'բոռեխ',
  'նստոց',
  'մոմեր',
  'ֆորտէ',
  'ջրցօղ',
  'սրահը',
  'տառեխ',
  'ջլթիկ',
  'որբիկ',
  'կոհակ',
  'գաւակ',
  'յաջող',
  'գջլել',
  'ֆինիշ',
  'փոխած',
  'նաւակ',
  'շեշտը',
  'խաթայ',
  'պատատ',
  'դիրքն',
  'կպչել',
  'տատիկ',
  'տռզել',
  'լալան',
  'ամոմց',
  'արմատ',
  'խարակ',
  'կորեխ',
  'ինկաւ',
  'պչրոտ',
  'մղվեց',
  'մեղվի',
  'ծնոքը',
  'կրճիկ',
  'բանակ',
  'պլոմբ',
  'բիւրօ',
  'ձայնը',
  'անդող',
  'ճաշին',
  'անգար',
  'ծածան',
  'բռռալ',
  'թիւրք',
  'ապրիք',
  'նկուղ',
  'ամսից',
  'արզնի',
  'թաբախ',
  'պրծիլ',
  'ձուոտ',
  'կաճէր',
  'կուզէ',
  'ջրգող',
  'գիտեր',
  'չատեր',
  'չուել',
  'ֆրիկը',
  'անկոթ',
  'թառիլ',
  'փշշոց',
  'քիմիա',
  'ոճիրի',
  'ջիրիթ',
  'ջրփող',
  'ոճերն',
  'շերեփ',
  'հեբեյ',
  'խոհեմ',
  'նշուի',
  'հնձան',
  'ճստալ',
  'ուզեց',
  'նրանք',
  'փղախտ',
  'չարթի',
  'հնչեղ',
  'քամու',
  'զատիչ',
  'բացիկ',
  'շլորի',
  'տուող',
  'թևերն',
  'գարթը',
  'կտավը',
  'նոյնն',
  'ձձում',
  'յիմար',
  'հնչէր',
  'զանգի',
  'անծիր',
  'մարալ',
  'անտես',
  'ապուռ',
  'կորիզ',
  'շինել',
  'արդար',
  'պոլոզ',
  'կորել',
  'առաջի',
  'պոզատ',
  'այգին',
  'եփում',
  'լացել',
  'խաւին',
  'մսխիլ',
  'մեղրի',
  'դադար',
  'սաներ',
  'կոպեկ',
  'կոչել',
  'ճնշեն',
  'կոտեմ',
  'թումբ',
  'եկանք',
  'աւշակ',
  'վիշտը',
  'փարոս',
  'երիզը',
  'բեմին',
  'բոցեղ',
  'ձայնի',
  'կնքել',
  'ծպտոց',
  'կտածը',
  'փնչոց',
  'մէկիկ',
  'զօռով',
  'գաթայ',
  'շնաբն',
  'ճիրան',
  'կարգը',
  'գնորդ',
  'շփման',
  'խոպոպ',
  'քարափ',
  'տոհմի',
  'անբաւ',
  'փախստ',
  'կաթիլ',
  'ակտիւ',
  'կարատ',
  'շաքար',
  'տաբատ',
  'ազոտը',
  'ծխանք',
  'չեղած',
  'շարան',
  'ափկալ',
  'որոնք',
  'սառիր',
  'ցեղին',
  'հսկող',
  'իրրեւ',
  'տուէք',
  'քեմոն',
  'դոզայ',
  'փչում',
  'կետով',
  'հինին',
  'պոեմը',
  'թուրը',
  'խօսքը',
  'աղեղն',
  'տարան',
  'լխկիլ',
  'ցոյցը',
  'գանկի',
  'կանալ',
  'կարոս',
  'աղջիկ',
  'քչնալ',
  'բկլատ',
  'ավագն',
  'տալէն',
  'տրված',
  'թէզեր',
  'գուռի',
  'եզրին',
  'էնպէս',
  'քէֆով',
  'տեսայ',
  'ցերեկ',
  'վառեկ',
  'մաղիլ',
  'լաչակ',
  'բրդից',
  'շենքի',
  'նշենի',
  'ուտող',
  'թեքատ',
  'վոքալ',
  'տալու',
  'բուէճ',
  'երբեմ',
  'հույս',
  'ջոկատ',
  'տորոն',
  'արկին',
  'փոկել',
  'նաբաթ',
  'մայրս',
  'անթիք',
  'վզիկի',
  'ռաջայ',
  'գամել',
  'փտում',
  'կրնաք',
  'քամոտ',
  'ճուալ',
  'բներգ',
  'ծրում',
  'երթան',
  'տարար',
  'վեջին',
  'տոմար',
  'աղօթք',
  'խշտիք',
  'կռիվը',
  'բոբիկ',
  'ֆոնին',
  'դիակի',
  'շրիտա',
  'կլանէ',
  'սուսր',
  'այլեւ',
  'թաղյա',
  'ֆրոնտ',
  'հաչել',
  'միսէն',
  'կրճատ',
  'միրէյ',
  'ցայել',
  'կպչան',
  'անխնա',
  'վարէր',
  'չարու',
  'անձաւ',
  'որտեղ',
  'անմոռ',
  'վաթան',
  'մտնէր',
  'անսպի',
  'հաւիկ',
  'ոջիլը',
  'աճիւն',
  'շուրջ',
  'պղինձ',
  'հռոմը',
  'նստան',
  'ջրհատ',
  'զատեն',
  'չէինք',
  'սուգը',
  'արևոտ',
  'լմփատ',
  'էնքան',
  'օծեալ',
  'ղմմաթ',
  'հարած',
  'սրագի',
  'խնթալ',
  'տորֆի',
  'քոսակ',
  'տոմսի',
  'տակառ',
  'բետոն',
  'ընկաւ',
  'ջոդին',
  'ցցորմ',
  'անհող',
  'գոզալ',
  'բիոմի',
  'շտկել',
  'աղճատ',
  'դիտած',
  'զննել',
  'ազդակ',
  'բուրջ',
  'կէսին',
  'շարաւ',
  'թիթեղ',
  'մյւոս',
  'դղիրդ',
  'ծիրան',
  'իսլամ',
  'արփիկ',
  'եռման',
  'տապան',
  'էլիսի',
  'մերան',
  'աղցան',
  'զատկի',
  'խաշու',
  'թխակն',
  'վրանը',
  'նարեկ',
  'լփռոտ',
  'հասու',
  'թաթար',
  'ճիւղի',
  'խաբել',
  'խաղից',
  'բաղեղ',
  'բիրին',
  'խօսիլ',
  'էսչափ',
  'թելել',
  'երգեց',
  'հաւան',
  'կէսէմ',
  'պզուկ',
  'պարտի',
  'բանիլ',
  'պայտի',
  'ազդող',
  'տրցակ',
  'երգչի',
  'ռումբ',
  'թժչին',
  'շշմած',
  'էտիւդ',
  'սիւիտ',
  'ամենա',
  'թոդան',
  'փակեղ',
  'հյութ',
  'պստիկ',
  'տնպահ',
  'դէպքը',
  'ոսպիկ',
  'նազար',
  'փռենի',
  'տխեղծ',
  'ուսին',
  'փրթիկ',
  'ֆսսալ',
  'անքիս',
  'արգամ',
  'յենակ',
  'շպարի',
  'ծխուկ',
  'բերնի',
  'զաման',
  'թորին',
  'չգտաւ',
  'փարիլ',
  'ճիմին',
  'կռճել',
  'ճումբ',
  'արխիւ',
  'զիջեց',
  'ելլել',
  'պառաւ',
  'պալէի',
  'աջլիկ',
  'նստել',
  'անհաս',
  'թղթել',
  'գայիք',
  'բեգար',
  'սալակ',
  'նշաձև',
  'ծածկի',
  'ասինք',
  'նշանն',
  'լեմբը',
  'թխուի',
  'բեմբի',
  'մազեղ',
  'իզմիր',
  'պղնձե',
  'կտաւն',
  'վհուկ',
  'կոճատ',
  'սքոթը',
  'վարեն',
  'տեւիի',
  'մարսի',
  'լարան',
  'առկախ',
  'տօնեն',
  'ուրար',
  'ակնոց',
  'ցիկլի',
  'թասիբ',
  'կպում',
  'զրոյց',
  'կարսի',
  'նմոյշ',
  'փիլաւ',
  'մեզմէ',
  'թուխպ',
  'կեսին',
  'բուշտ',
  'լորիկ',
  'թացոտ',
  'բզզոց',
  'եկչել',
  'անխոց',
  'դէպքի',
  'անլար',
  'ոսկյա',
  'լնգալ',
  'գիծին',
  'ձվերը',
  'թփերը',
  'սերած',
  'գտնել',
  'նաւթի',
  'թռչող',
  'քակած',
  'մախաղ',
  'բաժնի',
  'կարժէ',
  'որսով',
  'քսուք',
  'տակաւ',
  'մետիա',
  'երկեր',
  'մէկու',
  'թառափ',
  'բայել',
  'որակի',
  'քաւոր',
  'դղեակ',
  'չաթալ',
  'պալար',
  'հաշու',
  'ձեռքէ',
  'ափեզր',
  'կոչած',
  'քնում',
  'շնչել',
  'շաւիղ',
  'քաղել',
  'պիսակ',
  'լարով',
  'տոհմը',
  'դռռոց',
  'տխուր',
  'փաթաթ',
  'ծիծեռ',
  'ձուկի',
  'ինչքի',
  'ֆրանկ',
  'խարել',
  'տժժան',
  'դոփոց',
  'անզեն',
  'կէյթց',
  'անուս',
  'տրվել',
  'ընկայ',
  'խախտի',
  'պահել',
  'պոռոտ',
  'օդուժ',
  'յուղն',
  'ցայտք',
  'ոռնալ',
  'ամիսն',
  'կոյտի',
  'գօտիի',
  'սնկիկ',
  'դափնի',
  'թարախ',
  'տօնին',
  'հեւոց',
  'կողմի',
  'խեցին',
  'սնանի',
  'կալան',
  'բեմէն',
  'գարաժ',
  'կիրքը',
  'ափսոս',
  'տանիլ',
  'ցրտից',
  'ծածկը',
  'միջակ',
  'սառած',
  'դարով',
  'շեղբի',
  'մտրակ',
  'ցցչոր',
  'ճանառ',
  'ադրիկ',
  'պրոնզ',
  'խմրիլ',
  'որդել',
  'քոշակ',
  'տիրէր',
  'գիջին',
  'դիպակ',
  'բախոց',
  'ծլանգ',
  'սարքը',
  'ջուրք',
  'յօդեր',
  'հազին',
  'խմբակ',
  'իրանը',
  'կոլոր',
  'ջուալ',
  'աթլետ',
  'այնեղ',
  'ափիոն',
  'մանիշ',
  'կէսէն',
  'փաստի',
  'զենքի',
  'խարան',
  'փոսիկ',
  'եզակի',
  'ճառով',
  'քորոց',
  'ճչում',
  'բառեր',
  'ճիւաղ',
  'ինկար',
  'նաւել',
  'բշտոտ',
  'ընկար',
  'սնուի',
  'վեհեր',
  'փլված',
  'փայլը',
  'հօտով',
  'գալէն',
  'թիմին',
  'բարով',
  'խաղեր',
  'գոտու',
  'լցոնն',
  'չփոխէ',
  'կրցան',
  'գծում',
  'գաճաճ',
  'ալեակ',
  'չաման',
  'քերծէ',
  'քանզի',
  'տերեւ',
  'իրաքի',
  'դմփիլ',
  'էդիթը',
  'երգէն',
  'շարլֆ',
  'խռուկ',
  'փախար',
  'դդմաճ',
  'բիձայ',
  'պելլը',
  'լուրը',
  'ներու',
  'գրեցի',
  'ժաժիկ',
  'մինիկ',
  'նարօտ',
  'դուրս',
  'տատէն',
  'կամքը',
  'փողոց',
  'ցրդոն',
  'բայէն',
  'ուտիչ',
  'պտտան',
  'գաւիթ',
  'հպարտ',
  'ժպիտը',
  'փոստը',
  'շէնքի',
  'դիպաւ',
  'ծլոնգ',
  'թողու',
  'պաճոտ',
  'թիրախ',
  'թռիչք',
  'բանտի',
  'բլիթի',
  'խեւիկ',
  'գծերի',
  'գլուխ',
  'պրտու',
  'ցանքս',
  'չոքել',
  'անհօտ',
  'բիբար',
  'դռռալ',
  'ճտերի',
  'ոգուն',
  'սփռել',
  'ժխտող',
  'միազմ',
  'խոնաւ',
  'տոտեմ',
  'թթենի',
  'շողալ',
  'ծնողի',
  'լեսան',
  'դժկամ',
  'ածուխ',
  'կախոց',
  'փնջիկ',
  'վերէն',
  'խճուճ',
  'կռվին',
  'ջիգան',
  'գորով',
  'սրտէն',
  'ուէսթ',
  'երբևէ',
  'այրոց',
  'շոյել',
  'կորավ',
  'ծախու',
  'ուղու',
  'կանաչ',
  'տուղտ',
  'դրըխկ',
  'հսկիչ',
  'փորեղ',
  'մանած',
  'շաման',
  'հիւրը',
  'կեղևը',
  'ավազի',
  'ամպել',
  'ապտակ',
  'զեռալ',
  'ասոյթ',
  'ճայիկ',
  'հետոյ',
  'կծղիլ',
  'նիքին',
  'բրինձ',
  'խմիչք',
  'կանաչ',
  'բաժակ',
  'արուս',
  'խունկ',
  'բարեւ',
  'եղինձ',
  'հատու',
  'խռովք',
  'կամար',
  'դարան',
  'դարակ',
  'կացին',
  'կրկէս',
  'կրիայ',
  'շեղել',
  'սրճեփ',
  'ծանակ',
  'թուխս',
  'թուրմ',
  'թուրծ',
  'պղինձ',
  'պղտոր',
  'բոսոր',
  'գերան',
  'ջրվէժ',
  'կիզիչ',
  'դժխեմ',
  'դաժան',
  'վեղար',
  'բռնակ',
  'բռնիչ',
  'խուրձ',
  'խմիչք',
  'կանաչ',
  'բաժակ',
  'արուս',
  'խունկ',
  'բարեւ',
  'եղինձ',
  'հատու',
  'խռովք',
  'կամար',
  'դարան',
  'դարակ',
  'կացին',
  'կրկէս',
  'կրիայ',
  'շեղել',
  'սրճեփ',
  'ծանակ',
  'թուխս',
  'թուրմ',
  'թուրծ',
  'պղինձ',
  'պղտոր',
  'բոսոր',
  'գերան',
  'ջրվէժ',
  'կիզիչ',
  'դժխեմ',
  'դաժան',
  'վեղար',
  'բռնակ',
  'բռնիչ',
  'խուրձ',
  'արմատ',
  'ախորժ',
  'ազդակ',
  'ամբար',
  'ատեան',
  'անուս',
  'անարգ',
  'ալիւր',
  'բզզոց',
  'գետակ',
  'գիտակ',
  'գազան',
  'գիսակ',
  'դժկամ',
  'դժխեմ',
  'եղնիկ',
  'երկաթ',
  'երինջ',
  'ծանակ',
  'կաւիճ',
  'կապիճ',
  'կոպիճ',
  'կածան',
  'կակաչ',
  'խոպան',
  'խրթին',
  'սակառ',
  'հագագ',
  'հաճոյ',
  'համակ',
  'համեմ',
  'համեղ',
  'հառաչ',
  'հասու',
  'հատու',
  'հարոց',
  'հաւաք',
  'հեծիկ',
  'հեղեղ',
  'հերոս',
  'հմայք',
  'հնարք',
  'հնդիկ',
  'հուրի',
  'հռչակ',
  'հրդեհ',
  'հրթիռ',
  'հօտաղ',
  'ձագար',
  'ձախող',
  'ձկնիթ',
  'ձձում',
  'ձունձ',
  'ճագար',
  'ճապաղ',
  'ճռինչ',
  'մագիլ',
  'քուրձ',
  'քուրջ',
  'մակար',
  'մարար',
  'մական',
  'մահակ',
  'մաճար',
  'մայոց',
  'մասին',
  'մեղայ',
  'մրրիկ',
  'մրուր',
  'մունջ',
  'մռութ',
  'մոլոր',
  'մոլոշ',
  'մտացի',
  'մողէզ',
  'ուշիմ',
  'խմիչք',
  'կանաչ',
  'բաժակ',
  'արուս',
  'խունկ',
  'բարեւ',
  'եղինձ',
  'հատու',
  'խռովք',
  'կամար',
  'դարան',
  'դարակ',
  'կացին',
  'կրկէս',
  'կրիայ',
  'շեղել',
  'սրճեփ',
  'ծանակ',
  'թուխս',
  'թուրմ',
  'թուրծ',
  'պղինձ',
  'պղտոր',
  'բոսոր',
  'գերան',
  'ջրվէժ',
  'կիզիչ',
  'դժխեմ',
  'դաժան',
  'վեղար',
  'բռնակ',
  'բռնիչ',
  'խուրձ',
  'արմատ',
  'ախորժ',
  'ազդակ',
  'ամբար',
  'ատեան',
  'անուս',
  'անարգ',
  'ալիւր',
  'բզզոց',
  'գետակ',
  'գիտակ',
  'գազան',
  'գիսակ',
  'դժկամ',
  'դժխեմ',
  'եղնիկ',
  'երկաթ',
  'երինջ',
  'ծանակ',
  'կաւիճ',
  'կապիճ',
  'կոպիճ',
  'կածան',
  'կակաչ',
  'խոպան',
  'խրթին',
  'սակառ',
  'հագագ',
  'հաճոյ',
  'համակ',
  'համեմ',
  'համեղ',
  'հառաչ',
  'հասու',
  'հատու',
  'հարոց',
  'հաւաք',
  'հեծիկ',
  'հեղեղ',
  'հերոս',
  'հմայք',
  'հնարք',
  'հնդիկ',
  'հուրի',
  'հռչակ',
  'հրդեհ',
  'հրթիռ',
  'հօտաղ',
  'ձագար',
  'ձախող',
  'ձկնիթ',
  'ձձում',
  'ձունձ',
  'ճագար',
  'ճռինչ',
  'մագիլ',
  'քուրձ',
  'քուրջ',
  'մակար',
  'մարար',
  'մական',
  'մահակ',
  'մաճար',
  'մայոց',
  'մասին',
  'մեղայ',
  'մրրիկ',
  'մրուր',
  'մունջ',
  'մռութ',
  'մոլոր',
  'մոլոշ',
  'մտացի',
  'մողէզ',
  'ուշիմ',
  'չեչեն',
  'ծափել',
  'յենիլ',
  'աքացի',
  'շշմիլ',
  'կլլել',
  'օրօրք',
  'ջրկիր',
  'վրինջ',
  'երինջ',
  'մաղել',
  'շինիչ',
  'խիկար'
]
